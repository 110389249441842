import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {  userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import SunEditor from 'suneditor-react';
const Yeni = ({ detay, setDetay, setData }) => {

    const [formValue, setFormValue] = useState({
        title: '', aciklama: '', url: '', etiketler: '', metin: ''

    })

    const [editor, seteditor] = useState();
    const [gorsel, setGorsel] = useState();

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });


 




    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }

    const onEkle = () => {
        if (detay) {


            if (formValue.title.length < 2) {
                seterrorS({
                    uyari: { error: true, message: "Başlık   giriniz.", tip: "danger" },
                });

                return;
            }
            if (!gorsel) {
                seterrorS({
                    uyari: { error: true, message: "blog görseli yükleyiniz.", tip: "danger" },
                });

                return;
            }


            setbuttonOk(true);
            var form_data = new FormData();

            form_data.append("file", gorsel);

            formValue.metin = editor;
            for (var key in formValue) {
                form_data.append(key, formValue[key]);
            }

            userPut("EB_Blog/Yeni", form_data)
                .then((a) => {
                    setData(a.data.liste);
                    setbuttonOk(false);

                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });
                    setDetay();
                    setFormValue({
                        ad: '', link: '', sira: 5

                    })

                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() =>{ setDetay();seteditor();}}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div class="mb3 row">
                                <label class="form-label col-sm-2 ">Görsel</label>
                                <div class="col-sm-10">
                                    <input
                                        type="file"
                                        name="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={e => e.target.files?.length > 0 && setGorsel(e.target.files[0])}
                                    />
                                </div>
                            </div>



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Başlık :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="title" name="title" className="form-control" onChange={handleChange} value={formValue.title} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Kısa Açıklama :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="aciklama" name="aciklama" className="form-control" onChange={handleChange} value={formValue.aciklama} />
                                </div>
                            </div>



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Url :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="url" name="url" className="form-control" onChange={handleChange} value={formValue.url} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Etiketler :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="etiketler" name="etiketler" className="form-control" onChange={handleChange} value={formValue.etiketler} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-lg-3 control-label"> Metin:</label>
                                <div className="col-lg-12">

                                    <SunEditor defaultValue={editor} onChange={seteditor} name="metin" setOptions={{
                                        buttonList: [
                                            // default
                                            ['undo', 'redo'],
                                            [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['fontColor', 'hiliteColor', 'textStyle'],
                                            ['removeFormat'],
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                                            ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save', 'template'],
                                            ['-right', 'image', 'video', 'audio', 'link'],

                                        ]
                                    }} />


                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => {setDetay();seteditor();}}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni