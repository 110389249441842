import React, { useState, useEffect } from 'react'
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import { userGetList } from '../../functionList/axiosComp';
import Yeni from './components/yeni';
import Detay from './components/detay';
import Sil from './components/sil';


import ModulEkle from './components/modulEkle';
import ModulDetay from './components/modulDetay';
import { Link } from 'react-router-dom';
import StyleCss from './components/styleCss';

const Moduller = () => {
  const [data, setData] = useState();
  const [modulList, setModulList] = useState();
  const [modulTypes, setModulTypes] = useState();

  const [modulN, setModulN] = useState('Home');

  const [yeni, setYeni] = useState();
  const [detay, setDetay] = useState();
  const [sil, setSil] = useState();


  const [yeniMdl, setYeniMdl] = useState();
  const [detayMdl, setDetayMdl] = useState();


  const [styleB, setStyleB] = useState();
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {
    setData()
    userGetList("EB_Modul/Index?name=" + modulN)
      .then((a) => {
        console.log(a.data.liste)
        setModulList(a.data.mooduller)
        setModulTypes(a.data.altModul)
        setData(a.data.liste);


      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
        setData();
        return;
      });
  }, [modulN]);


  return (
    <div class="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

      <StyleCss detay={styleB} setDetay={setStyleB}></StyleCss>

      <Yeni detay={yeni} setDetay={setYeni} setData={setData} modul={modulN}></Yeni>

      <Detay detay={detay} setDetay={setDetay} setData={setData}></Detay>
      <Sil detay={sil} setDetay={setSil} setData={setData} ></Sil>

      <ModulEkle detay={yeniMdl} setDetay={setYeniMdl} setData={setData} modulTypes={modulTypes} setDetayMdl={setDetayMdl}></ModulEkle>
      <ModulDetay detay={detayMdl} setDetay={setDetayMdl} setData={setData}></ModulDetay>

      <div className='card'>
        <div className="card-body">
          <h4>Modül bölümü </h4>
          <p>Bu bölümden genel içerikleri yöneyebilirsiniz.</p>

          <div className="text-end">
            <button onClick={() => setStyleB(true)} className="btn btn-info">
             Css
            </button>     <button onClick={() => setYeni(true)} className="btn btn-success">
              <i className="fas fa-plus"></i> Yeni
            </button>
          </div>


          <div className="modul"> </div>

          <div className="row">

            <div className='col-md-3'><ul className="list-group">
              {modulList && modulList.map(a => <li key={a.type} className={"list-group-item " + (a.type === modulN ? "list-group-item-action active" : "")} onClick={(e) => setModulN(a.type)}> {a.desc}  </li>)}</ul>

            </div>

            <div className='col-md-9'>{data &&
              <>
                <div className='modulUl'>

                  {data && data.length > 0 ?
                    data.map((a, index) => (
                      <div className={'modulLi ' + (!a.durum && "bg-red")} key={a.id}>
                        <div><Link to="" onClick={() => setDetay(a.id)}  > {a.title}</Link>

                          {!a.sabitM && <div className='btnMdl'>
                            <button
                              className="btn btn-info btn-sm  m-1"
                              onClick={() => setYeniMdl({ Id: a.id, col: a.col })}
                            >
                              <i className="fa fa-plus"></i>
                            </button>

                            <button
                              className="btn btn-danger sil btn-sm  m-1"
                              onClick={() => setSil(a)}
                            >
                              <i className="fa fa-trash"></i>
                            </button></div>}


                        </div>

                        <div className='column'>

                          {a.column.map((b, i) => <>
                            <div key={b.id} className={'p-3 m-1 colm colm-' + (a.col)}>

                              {b.detayList.map(d => <div className='alt-b' ><Link to="" className={'text-body ' + (!d.durum && " red")} onClick={() =>
                                setDetayMdl({ id: d.id, col: a.col })
                              }> <strong>{d.type}.</strong> {d.title}    </Link></div>)}

                            </div></>)}

                        </div>
                      </div>
                    )) : <h5 className='text-center'>Geçerli modül bulunmuyor</h5>}

                </div>
              </>}

              {!data && <LoadSpin></LoadSpin>}</div>


          </div>


        </div>






      </div>
    </div >
  )
}

export default Moduller