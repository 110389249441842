import Layout from './Layout/layout'
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NewPassword from './login/newPassword';
import { useSelector } from 'react-redux';

import 'suneditor/dist/css/suneditor.min.css';


import Home from './pages/home/home'
import Iletisim from './pages/digerSayfalar/iletisim'
import Sss from './pages/digerSayfalar/sss'
import Sayfalar from './pages/sayfalar/sayfalar'
import Bloglar from './pages/blog/bloglar';
import Bayiler from './pages/bayi/bayiler';
import Danismanlar from './pages/danisman/danismanlar';
import DanismanDetay from './pages/danisman/danisman-detay';
import Urunler from './pages/urun/urunler'
import Kategoriler from './pages/kategoriler/kategoriler'

import Login from './login/login'
import ForgetPassword from './login/forgetPassword'

import Smtp from './pages/smtp/smtp';
import SeoAyarlari from './pages/seoAyarlari/SeoAyarlari';
import MenuAyarlari from './pages/menuAyarlari/MenuAyarlari';
import UrlAyarlari from './pages/urlAyarlari/urlAyarlari';


import DilBolge from './pages/dilBolge/dilBolge';
import SosyalMedya from './pages/sosyalMedya/sosyalMedya'
import AppLink from './pages/appLink/appLink'
import MailTemplate from './pages/mailTemplate/mailTemplate'
import Users from './pages/users/users';
import Password from './pages/users/password';
import Formlar from './pages/formlar/formlar';
import BayiDetay from './pages/bayi/bayi-detay';
import Banners from './pages/banner/banners';
import Gorsel from './pages/gorsel/gorsel';
import Moduller from './pages/tasarimArac/moduller';

function App() {
  return (
    <Routes>

      <Route path="/" element={<PrivateRoute children={<Layout> <Home /></Layout>} />} ></Route>
      <Route path="/index" element={<PrivateRoute children={<Layout> <Home /></Layout>} />} ></Route>
      <Route path='/index' element={<PrivateRoute children={<Layout> <Home /></Layout>} />}></Route>

      <Route path={"/kategoriler"} element={
        <PrivateRoute children={
          <Layout><Kategoriler /> </Layout>} />

      } />

      <Route path={"/ilanlar"} element={<PrivateRoute children={<Layout><Urunler /> </Layout>} />} />

      <Route path={"/danismanlar"} element={<PrivateRoute children={<Layout><Danismanlar /> </Layout>} />} />
      <Route path={"/danisman-detay-:id"} element={<PrivateRoute children={<Layout><DanismanDetay /> </Layout>} />} />

      <Route path={"/bayiler"} element={<PrivateRoute children={<Layout><Bayiler /> </Layout>} />} />
      <Route path={"/bayi-detay-:id"} element={<PrivateRoute children={<Layout><BayiDetay /> </Layout>} />} />

      <Route path={"/blog"} element={<PrivateRoute children={<Layout><Bloglar /> </Layout>} />} />

      <Route path={"/SifreGuncelle"} element={<PrivateRoute children={<Layout><Password /> </Layout>} />} />
      <Route path={"/Profilim"} element={<PrivateRoute children={<Layout><Users /> </Layout>} />} />


      <Route path={"iletisim"} element={<PrivateRoute children={<Layout><Iletisim /> </Layout>} />} />
      <Route path={"sss"} element={<PrivateRoute children={<Layout><Sss /> </Layout>} />} />
      <Route path={"Sayfalar"} element={<PrivateRoute children={<Layout> <Sayfalar /></Layout>} />} />


      <Route path={"formlar"} element={<PrivateRoute children={<Layout> <Formlar /></Layout>} />} />

      <Route path={"mail-template"} element={<PrivateRoute children={<Layout> <MailTemplate /></Layout>} />} />
      <Route path={"sosyal-medya"} element={<PrivateRoute children={<Layout> <SosyalMedya /></Layout>} />} />
      <Route path={"app-linkler"} element={<PrivateRoute children={<Layout> <AppLink /></Layout>} />} />

      <Route path={"banners"} element={<PrivateRoute children={<Layout> <Banners /></Layout>} />} />
      <Route path={"gorseller"} element={<PrivateRoute children={<Layout> <Gorsel /></Layout>} />} />
      <Route path={"moduller"} element={<PrivateRoute children={<Layout> <Moduller /></Layout>} />} />

      <Route path={"dil-bolge"} element={<PrivateRoute children={<Layout> <DilBolge /></Layout>} />} />
      <Route path={"menu-ayarlari"} element={<PrivateRoute children={<Layout> <MenuAyarlari /></Layout>} />} />
      <Route path={"url-ayarlari"} element={<PrivateRoute children={<Layout> <UrlAyarlari /></Layout>} />} />
      <Route path={"seo-ayarlari"} element={<PrivateRoute children={<Layout> <SeoAyarlari /></Layout>} />} />
      <Route path={"smtp-ayarlari"} element={<PrivateRoute children={<Layout> <Smtp /></Layout>} />} />

      <Route path={"login"} element={<Login />} />
      <Route path={"forget-password"} element={<ForgetPassword />} />
      <Route path={"new-password-:gid"} element={<NewPassword />} />


    </Routes>

  );
}

const PrivateRoute = ({ children }) => {

  const loggedIn = useSelector(state => Boolean(state.user));

  if (!loggedIn) {
    return <Navigate to={'/login'} />
  }

  return children;

}






// function PrivateRoute(children) {

//   const loggedIn = useSelector(state => Boolean(state.user));

//   if (!loggedIn) {
//     return <Navigate to={'/login'} />
//   }


//   return children;
// }

// const PrivateRoute = ({ component: Component, ...rest }) => {

//   const loggedIn = useSelector(state => Boolean(state.user));

//   if (!loggedIn) {
//       console.log("PrivateRoute: not logged in!")
//   }
//   else {
//       console.log("PrivateRoute: logged in")
//   }

//   return (
//       <Route
//           {...rest}
//           render={props => (
//               !loggedIn ? (

//                   <Navigate to="/Account-Login" />
//               ) : (

//                   <Component {...props} />
//               )
//           )} />
//   );
// };
export default App;
