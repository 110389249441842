import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Hatamodal from '../../functionList/hatamodal';
import { userGetList, userPost } from '../../functionList/axiosComp'
import LoadSpin, { Spin } from '../../functionList/loadSpin';
import Hata from '../../functionList/hata';
// import {faSyncAlt} from 


const Smtp = () => {

    const [isButton, setIsButton] = useState(false);
    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    const [data, setData] = useState()

     
const onTestMail = () => { 
    setIsButton(true);

    userGetList("Ebapi/smtpTestMail")
    .then((a) => { 
       console.log(a);
        seterrorS({
            uyari: { error: true, message:a.data.message, tip: "success" },
        });
    
        setIsButton(false);
    })
    .catch(function (error) {
        seterrorS({
            uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
       
        setIsButton(false);
      
    });


 }

    useEffect(() => {
        userGetList("Ebapi/smtpDetay")
            .then((a) => {

                setData(a.data);
                setIsButton(false);
            })
            .catch(function (error) {
                Hata();
                seterrorS({
                    uyari: { error: true, message: error.response.data.message, tip: "error" },
                });
                setData();
                setIsButton(false);
                return;
            });
    }, []);



    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });
    }


    const onSubmit = () => {
        setIsButton(true);

        var form_data = new FormData();

        for (var key in data) {
            form_data.append(key, data[key]);
        }


        userPost("Ebapi/smtpGuncelle/", form_data)
            .then((a) => {
                setData(a.data);
                setIsButton(false);

                seterrorS({
                    uyari: {
                        error: true,
                        message: "Bilgiler güncellendi",
                        tip: "success",
                    },
                });
            })
            .catch(function (error) {
                setIsButton(false);
                seterrorS({
                    uyari: {
                        tip: "error",
                        error: true,
                        message: error.response.data.message,
                    },
                });


                return;
            });



    }


    return (
        <div class="container-fluid"> <div className='card'>
            <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

            <div className="card-body">
                <h4>SMTP Bilgileri</h4>
                <div className="row">

                    {data &&
                        <form className="form-horizontal" id="form" name="form" autocomplete="off"  >

                            <div className="col-md-12">


                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Gönderilecek Mail Adı:</label>
                                    <div className="col-lg-12">
                                        <input type="text" id="mailAdi" name="mailAdi" className="form-control" onChange={handleChange} value={data.mailAdi} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Adres:</label>
                                    <div className="col-lg-12">
                                        <input type="text" id="site" name="site" className="form-control" onChange={handleChange} value={data.site} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-3 control-label">E-Mail:</label>
                                    <div className="col-lg-12">
                                        <input type="text" id="gmail" name="gmail" className="form-control" onChange={handleChange} value={data.gmail} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Şifre:</label>
                                    <div className="col-lg-12">
                                        <input type="text" id="gsifre" name="gsifre" className="form-control" onChange={handleChange} value={data.gsifre} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Port:</label>
                                    <div className="col-lg-12">
                                        <input type="text" id="port" name="port" className="form-control" onChange={handleChange} value={data.port} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-lg-3 control-label">SSl :</label>
                                    <div className="col-lg-12">
                                        <select className="form-control" defaultValue={data.enableessl} onChange={handleChange} name="enableessl" id="enableessl">
                                            <option value={false}>Yok</option>
                                            <option value={true}>Var</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Durum?:</label>
                                    <div className="col-lg-12">
                                        <select className="form-control" defaultValue={data.acik} onChange={handleChange} name="acik" id="acik">
                                            <option value={true}>Evet</option>
                                            <option value={false}>Hayır</option>
                                        </select>
                                    </div>
                                </div>
 

                            </div>

                            <div className="form-group mt-2">
                                <div className="text-end">

                              
                                    {isButton && <Button className="btn btn-info m-2" disabled ><Spin /> Bekleyiniz </Button>}

                                    {!isButton && <Button className="btn btn-info m-2" onClick={() => onTestMail()}>Test Mail Gönder  </Button>}


                                    {isButton && <Button  className="btn btn-success m-2" disabled ><Spin /> Bekleyiniz </Button>}

                                    {!isButton && <Button  className="btn btn-success m-2" onClick={() => onSubmit()}>Güncelle  </Button>}

                                </div>
                            </div>





                        </form>}

                    {!data && <LoadSpin></LoadSpin>}

                </div>
            </div>

        </div></div>



    )
}

export default Smtp