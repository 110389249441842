import React, { Component, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { userLogout } from '../redux/redux'

const Header = () => {
  var dispatch = useDispatch();

  const onCikis = () => {
    dispatch(userLogout())

  }

  return (
    <header id="page-topbar">

      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="images/logo-sm.png" alt="" height="70" />
              </span>
              <span className="logo-lg">
                <img src="images/logo-dark.png" alt="" height="70" />
              </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="images/logo-sm.png" alt="" height="70" />
              </span>
              <span className="logo-lg">
                <img src="images/logo-light.png" alt="" height="70" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm me-2 font-size-24 d-lg-none header-item waves-effect waves-light"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <i className="mdi mdi-menu"></i>
          </button>
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-notifications-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="ion ion-md-notifications"></i>
              <span className="badge bg-danger rounded-pill">3</span>
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 left-banner-spr"
              aria-labelledby="page-header-notifications-dropdown"
            >
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="m-0 font-size-16">Notification (3)</h5>
                  </div>
                </div>
              </div>
              <div data-simplebar style={{ maxHeight: "230px" }}>
                <a href="" className="text-reset notification-item">
                  <div className="media d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="mdi mdi-cart-outline"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 font-size-15 mb-1">
                        Your order is placed
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Dummy text of the printing and typesetting industry.
                        </p>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="#" className="text-reset notification-item">
                  <div className="media d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-warning rounded-circle font-size-16">
                        <i className="mdi mdi-message-text-outline"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 font-size-15 mb-1">
                        New Message received
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">You have 87 unread messages</p>
                      </div>
                    </div>
                  </div>
                </a>

                <a href="#" className="text-reset notification-item">
                  <div className="media d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-info rounded-circle font-size-16">
                        <i className="mdi mdi-glass-cocktail"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 font-size-15 mb-1">
                        Your item is shipped
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          It is a long established fact that a reader will
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="p-2 border-top">
                <a
                  className="btn btn-sm btn-link font-size-14 w-100 text-center"
                  href="j#"
                >
                  View all
                </a>
              </div>
            </div>
          </div>

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon left-bar-toggle waves-effect"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="mdi mdi-spin mdi-cog"></i>
            </button>

            <div
              className="dropdown-menu dropdown-menu-end left-banner-spr"
              aria-labelledby="dropdownMenuButton"
            >
              <Link className="dropdown-item" to="/Profilim">
                <i className="dripicons-user font-size-16 align-middle me-2"></i>
                Profilim
              </Link>
              <Link className="dropdown-item d-block" to="/SifreGuncelle">
                <i className="dripicons-gear font-size-16 align-middle me-2"></i>
                Şifre Güncelle
              </Link>

              <div className="dropdown-divider"></div>

              <Link className="dropdown-item" to="#" onClick={() => onCikis()}>
                <i className="dripicons-exit font-size-16 align-middle me-2"></i>
                Çıkış
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <i className="dripicons-device-desktop me-2"></i> Ana Ekran
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle arrow-none"

                    id="topnav-layout"
                    role="button"
                  >
                    <i className="dripicons-suitcase me-2"></i>İşlemler
                    <div className="arrow-down"></div>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="topnav-components"
                  >
                    <div className="dropdown">

                      <Link
                        to={"/kategoriler"}
                        className="dropdown-item "
                      >
                        <i className="dripicons-checklist me-2"></i> Kategoriler
                      </Link>

                      <Link
                        to={"/ilanlar"}
                        className="dropdown-item "
                      >
                        <i className="dripicons-archive me-2"></i> İlanlar
                      </Link>  <Link
                        to={"/danismanlar"}
                        className="dropdown-item "
                      >
                        <i className="dripicons-user-group me-2"></i> Danışmanlar
                      </Link>     <Link
                        to={"/bayiler"}
                        className="dropdown-item "
                      >
                        <i className="dripicons-store me-2"></i> Bayiler
                      </Link>



                    </div>


                  </div>
                </li>



                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle arrow-none"

                    id="topnav-layout"
                    role="button"
                  >
                    <i className="dripicons-clipboard me-2"></i>İçerik
                    <div className="arrow-down"></div>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="topnav-components"
                  >
                    <div className="dropdown">


                      <Link className="dropdown-item " to="/blog">
                        <i className="fa fa-text-width"></i> Bloglar
                      </Link>
                      <Link className="dropdown-item " to="/Sayfalar">
                        <i className="fa fa-list"></i> Sayfalar
                      </Link>

                    </div>


                  </div>
                </li>




                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle arrow-none"

                    id="topnav-layout"
                    role="button"
                  >
                    <i className="dripicons-photo-group me-2"></i>Görsel&Banner
                    <div className="arrow-down"></div>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="topnav-components"
                  >
                    <div className="dropdown">

 

                      <Link
                        to={"/banners"}
                        className="dropdown-item "
                      >
                        <i className="dripicons-duplicate me-2"></i> Banner
                      </Link>



                      <Link
                        to={"/gorseller"}
                        className="dropdown-item "
                      >
                        <i className="dripicons-photo"></i> Görsel
                      </Link>

                    </div>
                  </div>
                </li>









                <li className="nav-item">
                  <Link className="nav-link" to="/formlar">
                    <i className="fa fa-list"></i> Formlar
                  </Link>
                </li>


                <li className="nav-item dropdown">
                  <Link
                    to={"/moduller"}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="mdi mdi-view-module"></i> Modüller
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle arrow-none"

                    id="topnav-layout"
                    role="button"
                  >
                    <i className="dripicons-suitcase me-2"></i>Tanımlamalar
                    <div className="arrow-down"></div>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="topnav-components"
                  >
                    <div className="dropdown">
                      <a
                        className="dropdown-item dropdown-toggle arrow-none"
                        href="#"
                        id="topnav-email"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Genel Ayarlar <div className="arrow-down"></div>
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="topnav-email"
                      >
                        <Link
                          to={"/mail-template"}
                          className="dropdown-item"
                        >
                          Mail Template
                        </Link>
                        <Link
                          to={"/sosyal-medya"}
                          className="dropdown-item"
                        >
                          Sosyal Medya  Listesi
                        </Link>
                        <Link
                          to={"/app-linkler"}
                          className="dropdown-item"
                        >
                          Uygulama App Linkler
                        </Link>

                      </div>
                    </div>


                    <div className="dropdown">
                      <a
                        className="dropdown-item dropdown-toggle arrow-none"
                        href="#"
                        id="topnav-email"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Özel Ayarlar <div className="arrow-down"></div>
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="topnav-email"
                      >  <Link
                        to={"/seo-ayarlari"}
                        className="dropdown-item"
                      >
                          Başlık ve Seo Ayarları
                        </Link>
                        <Link
                          to={"/url-ayarlari"}
                          className="dropdown-item"
                        >
                          URL Ayarları
                        </Link>
                        <Link
                          to={"/menu-ayarlari"}
                          className="dropdown-item"
                        >
                          Menü Ayarları
                        </Link>
                        <Link
                          to={"/smtp-ayarlari"}
                          className="dropdown-item"
                        >
                          Smtp Ayarları
                        </Link>

                        <Link
                          to={"/dil-bolge"}
                          className="dropdown-item"
                        >
                          Dil ve Bölge Ayarları
                        </Link>
                      </div>
                    </div>


                  </div>
                </li>


              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="page-title-box">
        <div className="container-fluid">
          <div className="page-title-content">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4>Emlak Başkentine Hoşgeldiniz </h4>
              </div>

            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header