import React, { useState, useEffect } from 'react'
import {   userGetList } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import Detay from './components/detay';
import Sil from './components/sil';
import { getFormatDatetime } from '../../functionList/funcColl'
import BayiDanisman from './components/bayiDanisman';

const Formlar = () => {
  const [data, setData] = useState();

  const [yeni, setYeni] = useState();
  const [detay, setDetay] = useState();
  const [sil, setSil] = useState();


  const [bDetay, setbDetay] = useState();
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });



  useEffect(() => {
    userGetList("EB_FormListesi/Index")
      .then((a) => {
        console.log(a.data);
        setData(a.data);
      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
        setData();
        return;
      });
  }, []);






  return (
    <div className="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

      <BayiDanisman detay={bDetay} setDetay={setbDetay} setData={setData}></BayiDanisman>

      <Detay detay={detay} setDetay={setDetay} setData={setData}></Detay>
      <Sil detay={sil} setDetay={setSil} setData={setData} ></Sil>


      <div className='card'>
        <div className="card-body">
          <h4>Form Listesi  </h4>
          <p>Alış ,Satış ve İletişim formları bölümü.</p>


          <div className="row">

            {data &&
              <>
                <div className="table-responsive">
                  <table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th width="90">#</th>
                        <th>Tarih </th>
                        <th>Tip </th>
                        <th>Gönderen</th>
                        <th className='text-center' width="200">İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ?
                        data.map((a, index) => (
                          <tr key={a.id}  >
                            <td>{index + 1}</td>
                            <td>{getFormatDatetime(a.tarih)}</td>
                            <td><strong>{a.tip}</strong></td>
                            <td>{a.baslik}</td>

                            <td className='text-center'>

                              {a.okundu ? (
                                <button
                                  className="btn btn-warning btn-sm m-1"
                                  onClick={() => setDetay(a.id)}
                                >
                                  <i className="fa fa-envelope-open" aria-hidden="true"></i>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-sm m-1"
                                  onClick={() => setDetay(a.id)}
                                >
                                  <i className="fa fa-envelope" aria-hidden="true"></i>

                                </button>
                              )}

                              {a.bayiId === null ? (
                                <button
                                  className="btn btn-info btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'BA1', tipB: 'Bayiye Ata', item: { value: a.bayiId, label: a.bayiAdi } })}
                                >
                                  <i className="mdi  mdi-store-plus" aria-hidden="true"></i>
                                </button>
                              ) : a.bayiG === false ? (
                                <button
                                  className="btn btn-danger btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'BA1', tipB: 'Bayiye Ata', item: { value: a.bayiId, label: a.bayiAdi } })}
                                >
                                  <i className="mdi  mdi-store-remove" aria-hidden="true"></i>

                                </button>
                              ) : !a.bayiG ? (
                                <button
                                  className="btn btn-warning btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'BA1', tipB: 'Bayiye Ata', item: { value: a.bayiId, label: a.bayiAdi } })}
                                >
                                  <i className="mdi  mdi-store-outline " aria-hidden="true"></i>

                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'BA1', tipB: 'Bayiye Ata', item: { value: a.bayiId, label: a.bayiAdi } })}
                                >
                                  <i className="mdi  mdi-store" aria-hidden="true"></i>

                                </button>
                              )}


                              {a.danisId === null ? (
                                <button
                                  className="btn btn-info btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'DA1', tipB: 'Danışmana Ata', item: { value: a.danisId, label: a.danisman } })}
                                >
                                  <i className="mdi  mdi-account-circle-outline" aria-hidden="true"></i>
                                </button>
                              ) : a.danisG === false ? (
                                <button
                                  className="btn btn-danger btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'DA1', tipB: 'Danışmana Ata', item: { value: a.danisId, label: a.danisman } })}
                                >
                                  <i className="mdi  mdi-account-alert" aria-hidden="true"></i>

                                </button>
                              ) : !a.danisG ? (
                                <button
                                  className="btn btn-warning btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'DA1', tipB: 'Danışmana Ata', item: { value: a.danisId, label: a.danisman } })}
                                >
                                  <i className="mdi  mdi-account-circle" aria-hidden="true"></i>

                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-sm m-1"
                                  onClick={() => setbDetay({ id: a.id, tip: 'DA1', tipB: 'Danışmana Ata', item: { value: a.danisId, label: a.danisman } })}
                                >
                                  <i className="mdi  mdi-account-circle" aria-hidden="true"></i>

                                </button>
                              )}



                              <button
                                className="btn btn-danger  btn-sm m-1"
                                onClick={() => setSil(a)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        )) : <tr>
                          <td colSpan={8} className="text-center">Kayıt bulunmuyor.</td></tr>}
                    </tbody>
                  </table>
                </div>
              </>}

            {!data && <LoadSpin></LoadSpin>}

          </div>
        </div>






      </div>
    </div>
  )
}

export default Formlar