import axios from "axios";

const instance = axios.create({});

let urlD = "https://ixxi.com.tr/";
// let urlD = "https://localhost:44306/";
const getList = (url) => {
  return instance.get(urlD + url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};

const post = (url, formData) => {
  return instance.post(urlD + url, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};

const put = (url, formData) => {
  return instance.post(urlD + url, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};

const dell = (url) => {
  return instance.post(urlD + url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};




const userGetList = (url) => {
  
  return instance.get(urlD + url, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("users")
    },
  });
};

const userPost = (url, formData) => {
  return instance.post(urlD + url, formData, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("users"), "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};

const userPut = (url, formData) => {
  return instance.post(urlD + url, formData, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("users"), "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};

const userDell = (url) => {
  return instance.get(urlD + url, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("users"), "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
  });
};


export { getList, post, put, dell, userGetList, userPost, userPut, userDell, urlD };
