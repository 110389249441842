import React, { useState, useEffect } from 'react'
import {  userGetList } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import Detay from './companent/detay';


const UrlAyarlari = () => {
    const [sabitData, setSabitData] = useState();
    const [detay, setDetay] = useState();
    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });



    useEffect(() => {
        userGetList("EB_Url/Index")
            .then((a) => {

                setSabitData(a.data);
            })
            .catch(function (error) {
                Hata();
                seterrorS({
                    uyari: { error: true, message: error.response.data.message, tip: "error" },
                });
                setSabitData();
                return;
            });
    }, []);

    return (
        <div class="container-fluid">
            <Detay detay={detay} setDetay={setDetay} setData={setSabitData}></Detay>
            <div className='card'> <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>
                <div className="card-body">
                    <h4>URL Ayarları</h4>
                    <div className="row">

                        {sabitData &&
                            <>
                                <div class="table-responsive">
                                    <table class="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Url</th>
                                                <th>Link</th>
                                                <th className='text-center'>İşlem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sabitData && sabitData.length > 0 &&
                                                sabitData.map((a, index) => (
                                                    <tr>
                                                        <td>{index+1}</td>
                                                        <td>{a.ad}</td>
                                                        <td>{a.url1}</td>
                                                        <td className='text-center'>
                                                            <button
                                                                className="btn btn-success btn-sm m-1"
                                                                onClick={() => setDetay(a)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </button></td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>}

                        {!sabitData && <LoadSpin></LoadSpin>}

                    </div>
                </div>






            </div>
        </div>
    )
}

export default UrlAyarlari