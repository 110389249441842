import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getList, post, userGetList } from '../../../functionList/axiosComp';
import { getFormatDatetime } from '../../../functionList/funcColl';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';


const Detay = ({ detay, setDetay, setData }) => {


  const [formValue, setFormValue] = useState()
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {

    if (detay) {
      userGetList("EB_FormListesi/Detay/" + detay)
        .then((a) => {
          console.log(a.data);
          setFormValue(a.data.formvalue);
          setData(a.data.liste);
          setbuttonOk(false)


        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])





  // const handleChange = (event) => {


  //   setFormValue({
  //     ...formValue,
  //     [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
  //   });

  // }

  // const onSubmit = () => {
  //   if (detay) {
  //     if (formValue.ad.length < 2) {
  //       seterrorS({
  //         uyari: { error: true, message: "Platform adı giriniz.", tip: "danger" },
  //       });

  //       return;
  //     }

  //     setbuttonOk(true);
  //     var form_data = new FormData();

  //     for (var key in formValue) {
  //       form_data.append(key, formValue[key]);
  //     }

  //     post("EB_SMedyaUygulamalar/sMedyaGuncelle/" + detay, form_data)
  //       .then((a) => {
  //         setData(a.data);
  //         setbuttonOk(false);

  //         seterrorS({
  //           uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
  //         });


  //       })
  //       .catch(function (error) {
  //         setbuttonOk(false);
  //         seterrorS({
  //           uyari: { tip: "danger", error: true, message: error.response.data.message },
  //         });
  //         Hata(error);


  //         return;
  //       });
  //   }
  // }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              {(formValue.tip)} Formu Detayı
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              
              <div className="col-lg-12 text-end">
                {getFormatDatetime(formValue.tarih)}
              </div>



              <div className="form-group mb-4">
                <div className="col-lg-12">
                  <strong className=" control-label">Başlık :</strong>

                  {formValue.baslik}
                </div>
              </div>

              <div className="form-group mb-4 col-md-6">
                <div className="col-lg-12">
                  <strong className="control-label">Telefon :</strong>
                  {formValue.tel}
                </div>
              </div>
              <div className="form-group mb-4  col-md-6">
                <div className="col-lg-12">   <strong className=" control-label">Email :</strong>

                  {formValue.mail}
                </div>
              </div>
              <div className="form-group mb-4  col-md-6">
                <div className="col-lg-12"> <strong className="control-label">Metin :</strong>

                  {formValue.metin}
                </div>
              </div>


            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Kapat
                </Button>

              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay