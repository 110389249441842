import React, { useState, useEffect } from 'react'
import {  userGetList, userPost } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';

const Users = () => {

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
  const [profil, setProfil] = useState();
  const [buttonOk, setbuttonOk] = useState(false);

  useEffect(() => {
    userGetList("EB_Admin/Index")
      .then((a) => {
        setProfil(a.data);
        console.log(a.data)

      })
      .catch((error) => {
        Hata(error);
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
      });
  }, []);



  const HandleChange = (event) => {
    setProfil({
      ...profil,
      [event.target.name]: event.target.value,
    });
  };




  const onsubmit = (e) => {
    e.preventDefault();


    if (profil.sifre.length <= 4) {
      seterrorS({
        uyari: {
          error: true,
          message: "Şifrenizi girin.",
          tip: "danger",
        },
      });

      return false;
    } else if (profil.kad.length <= 2) {
      seterrorS({
        uyari: { error: true, message: "Kullanıcı adınızı  girin", tip: "danger" },
      });

      return false;
    } else if (profil.mail.length <= 2) {
      seterrorS({
        uyari: { error: true, message: "Mail adresinizi  girin", tip: "danger" },
      });

      return false;
    } else {


      setbuttonOk(true);

      var form_data = new FormData();

      for (var key in profil) {
        form_data.append(key, profil[key]);
      }

      userPost("EB_Admin/kullaniciGunc/", form_data)
        .then((a) => {

          seterrorS({
            uyari: {
              error: true,
              message: "Bilgileriniz güncellendi",
              tip: "success",
            },
          });

          setProfil(a.data);

        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });

          Hata(error);
          return;
        });




    }

    return false;
  };


  return (
    <>
      {" "}
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form class="custom-validation" onSubmit={(e) => onsubmit(e)}>
                  <h1>Profilim </h1>

                  {profil && (

                    <div>
                      <div class="mb-3 position-relative">
                        <label class="form-label">Şifreniz</label>
                        <div>
                          <input
                            type="password"
                            class="form-control"
                            name="sifre"
                            onChange={HandleChange}
                            value={profil.sifre}
                          />
                        </div>
                      </div>

                      <div class="mb-3 position-relative">
                        <label class="form-label">Kullanıcı Adı</label>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            name="kad"
                            onChange={HandleChange}
                            value={profil.kad}
                          />
                        </div>
                      </div>

                      <div class="mb-3 position-relative">
                        <label class="form-label">Email</label>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            name="mail"
                            onChange={HandleChange}
                            value={profil.mail}
                          />
                        </div>
                      </div>

                      <button class="btn btn-primary waves-effect waves-light"  >
                        Güncelle
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users