

import React from 'react'
import { Spinner } from 'react-bootstrap'
//  import Spinner from 'react-bootstrap/Spinner'

const LoadSpin = () => {
    return (
        <div class="text-center">

            <Spinner animation="border" role="status" >
                <span className="visually-hidden">Yükleniyor...</span>
            </Spinner>

        </div>
    )
}



export const Spin = () => {
    return (
        <div class="text-center">

            <Spinner animation="border" role="status" >
                <span className="visually-hidden"></span>
            </Spinner>

        </div>
    )
}
export default LoadSpin 