import React from 'react'
import { Link } from 'react-router-dom'

const NewPassword = () => {
    return (
        <div class="account-bg">
            <div class="account-pages my-5 pt-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6 col-xl-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="text-center mt-4">
                                        <div class="mb-3">
                                            <a href="index" class="auth-logo">

                                                <img src="/assets/images/logo-light.png" height="30" class="logo-light mx-auto" alt="" />
                                            </a>

                                        </div>
                                        <div class="p-3">
                                            <h4 class="font-size-18 text-muted mt-2 text-center mb-4">Yeni Şifre Belirle </h4>

                                            <form class="form-horizontal" method="post" autocomplete="off">

                                                <div class="mb-3  text-start">
                                                    <label for="useremail">Email</label>
                                                    <input type="email" class="form-control" id="useremail" name="useremail" placeholder="Email adresiniz" />
                                                </div>
                                                <div class="mb-3  text-start">
                                                    <label for="useremail">Şifre</label>
                                                    <input type="password" class="form-control" id="password" name="password" placeholder="Şifre " />
                                                </div>
                                                <div class="mb-3 text-start">
                                                    <label for="useremail">Yeni Şifre</label>
                                                    <input type="passwordT" class="form-control" id="passwordt" name="passwordt" placeholder="Şifre Tekrar" />
                                                </div>

                                                <div class="mb-3 row mt-4">
                                                    <div class="col-12 text-end">
                                                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit">
                                                            Güncelle
                                                        </button>
                                                    </div>
                                                </div>


                                            </form>

                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-12 mt-4">
                                    <Link to="/login" class="text-muted">
                                        <i class="mdi mdi-lock"></i> Giriş yap
                                    </Link> |
                                    <Link to="/forget-password" class="text-muted">
                                        <i class="mdi mdi-lock"></i> Şifremi Unuttum
                                    </Link>
                                </div>
                            </div>
                            <div class="mt-5 text-center">

                                <p>
                                    ©
                                    <script>document.write(new Date().getFullYear())</script>2022
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPassword