import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { urlD, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Foto = ({ show, setshow, setrefresh }) => {
  const [modal, setmodal] = useState(false);
  const [modalsil, setmodalsil] = useState(false);
  const [buttonOk, setbuttonOk] = useState(false);

  const [gfoto, setgfoto] = useState();
  const [formValue, setformValue] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: "" },
  });

  useEffect(() => {


    if (show) {
      setmodalsil(false);
      setgfoto(show && show.img);
      setmodal(true);
    }
  }, [show]);

  const handleClose = () => {
    setshow();
    setmodal(false);
  };

  const onSubmit = () => {
    setbuttonOk(true);

    var form_data = new FormData();

    form_data.append("file", formValue);

    form_data.append("tip", show.folder);

    userPost("EB_Bayiler/DetailsGorsel/" + show.uid, form_data)
      .then((a) => {
        setrefresh(a.data);
        setbuttonOk(false);

        handleClose();
        seterrorS({
          uyari: { error: true, message: "Bilgiler güncellendi", tip: "success" },
        });
      })
      .catch(function (error) {
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);

        Hata(error)
        return;
      });
  };

  const onsil = () => {
    setbuttonOk(true);


    var form_data = new FormData();
    form_data.append("tip", show.folder);


    userPost("EB_Bayiler/DetailsGorselKaldir/" + show.uid, form_data)
      .then((a) => {
        setrefresh(a.data);
        setbuttonOk(false);
        setmodalsil(false);
        handleClose();
        seterrorS({
          uyari: { error: true, message: "Görsel kaldırıldı", tip: "success" },
        });
      })
      .catch(function (error) {
        seterrorS({
          uyari: { error: true, message: error.response.data.message },
        });
        setbuttonOk(false);

        Hata(error)
        return;
      });
  };

  const handleChange = (event) => {
    let img = event.target.files[0];

    setformValue(img);

    console.log(formValue);
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {show && (
        <>
          <Modal show={modal} onHide={handleClose} size={"sm"}>
            <Modal.Header closeButton>
              <Modal.Title> {show.baslik}  </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form onSubmit={() => onSubmit()}>
                <div class="text-center">
                  {show && show.img ? (
                    <img
                      className="me-3 rounded-circle avatarprofil"
                      src={urlD + "images/" + show.folder + "/" + show.img}

                    />
                  ) : (
                    <img
                      className="me-3 rounded-circle avatarprofil"
                      src={"images/image.png"}
                    />
                  )}
                </div>
                <div class="mb3 row">
                  <label class="form-label col-sm-2 ">Foto</label>
                  <div class="col-sm-10">
                    <input
                      type="file"
                      name="file"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Kapat
                  </Button>
                  {show && show.img && (
                    <Button variant="danger" onClick={() => setmodalsil(true)}>
                      <i className="fa fa-trash"> </i> Sil
                    </Button>
                  )}

                  <Button variant="success" onClick={() => onSubmit()}>
                    Güncelle
                  </Button>
                </>
              )}
              {buttonOk && <LoadSpin />}
            </Modal.Footer>
          </Modal>

          <Modal show={modalsil} onHide={() => setmodalsil(false)} size={"sm"}>
            <Modal.Header closeButton>
              <Modal.Title>Foto Kaldır</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>Fotograf kaldırılsın mı?</div>
            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setmodalsil(false)}
                  >
                    Vazgeç
                  </Button>

                  <Button variant="danger" onClick={() => onsil()}>
                    <i className="fa fa-trash"> </i> Sil
                  </Button>
                </>
              )}
              {buttonOk && <LoadSpin />}
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default Foto