import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
// import { ModulSelect, ClassSize } from '../../../functionList/moduleSize';

const ModulEkle = ({ detay, setDetay, setData, modulTypes, setDetayMdl }) => {

    const [formValue, setFormValue] = useState({
        mtitle: '', mtitleG: false, mdesc: '', mdescG: false, type: '', col: 1, sira: 1, css: '',

    })

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }

    const onEkle = () => {


        if (detay) {


            setbuttonOk(true);
            var form_data = new FormData();

            for (var key in formValue) {
                form_data.append(key, formValue[key]);
            }
            form_data.append("mcid", detay.Id);



            userPut("EB_Modul/SubModulYeni", form_data)
                .then((a) => {

                    console.log("-*-")
                    console.log(a.data)
                    setDetayMdl(a.data.mId);
                    setData(a.data.liste);
                    setDetay();
                    setbuttonOk(false);


                    setFormValue({
                        mtitle: '', mtitleG: false, mdesc: '', mdescG: false, type: '', col: 1, sira: 1, css: '',

                    })




                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Modül Eklendi
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div className="mb-4 col-md-12">
                                <label className="col-lg-12 control-label">Modül  :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.type} onChange={handleChange} name="type" id="type">
                                        <option value={""}>Modül Seçiniz</option>
                                        {modulTypes && modulTypes.map((a) =>
                                            <option value={a.type}>{"<" + a.type + ">"} {a.desc}</option>
                                        )}
                                    </select>
                                </div>
                            </div>



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Sütun :</label>
                                <div className="col-lg-12">
                                    <select type="date" id="col" name="col" className="form-control" onChange={handleChange} value={formValue.col}>
                                        {[...Array(parseInt(detay.col))].map((a, x) => <option>{x + 1}</option>)}


                                    </select>
                                </div>
                            </div>



                            <div className="mb-4 col-md-8">
                                <label className="col-lg-12 control-label">Başlık :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="mtitle" name="mtitle" className="form-control" onChange={handleChange} value={formValue.mtitle} />
                                </div>
                            </div>


                            <div className="mb-4 col-md-4">
                                <label className="col-lg-12 control-label"> Başlık Göster :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.mtitleG} onChange={handleChange} name="mtitleG" id="mtitleG">
                                        <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-4 col-md-8">
                                <label className="col-lg-12 control-label">Açıklama :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="mdesc" name="mdesc" className="form-control" onChange={handleChange} value={formValue.mdesc} />
                                </div>
                            </div>


                            <div className="mb-4 col-md-4">
                                <label className="col-lg-12 control-label"> Açıklama Göster :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.mdescG} onChange={handleChange} name="mdescG" id="mdescG">
                                        <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option>
                                    </select>
                                </div>
                            </div>



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Sıra :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Css :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="css" name="css" className="form-control" onChange={handleChange} value={formValue.css} />
                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default ModulEkle