import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import Select from 'react-select';
import SunEditor from 'suneditor-react';


const Detay = ({ detay, setDetay, setData }) => {


  const [editor, seteditor] = useState('');
  const [formValue, setFormValue] = useState()
  const [buttonOk, setbuttonOk] = useState(false);


  const [bayiList, setbayiList] = useState([]);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {

    userGetList("EB_Bayiler/select").then(a => { setbayiList(a.data); console.log(a.data) })
  }, [])



  useEffect(() => {

    if (detay) {
      userGetList("EB_Danismanlar/GDetay/" + detay)
        .then((a) => {
          console.log(a.data)
          setFormValue(a.data);
          seteditor(a.data.detay)
          setbuttonOk(false)

        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])


  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });
  }

  const selectChange = (event, value) => {
    setFormValue({
      ...formValue,
      [event]: value
    });
  }



  const onSubmit = () => {

    if (detay) {
      console.log(editor)

      if (formValue.adSoyad.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Danışman adını giriniz.", tip: "danger" },
        });

        return false;
      }
      else if (!formValue.lMail) {

        seterrorS({
          uyari: { error: true, message: " Giriş email  adresi giriniz.", tip: "danger" },
        });
        return;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValue.lMail)) {
        seterrorS({
          uyari: { error: true, message: "Geçerli 'Giriş Mail'   adresi giriniz.", tip: "danger" },
        });
        return;
      }
      else {

        setbuttonOk(true);
        var form_data = new FormData();

        formValue.detay = editor;
        for (var key in formValue) {
          form_data.append(key, formValue[key]);
        }


        userPost("EB_Danismanlar/Guncelle/" + detay, form_data)
          .then((a) => {
            setData(a.data);
            setbuttonOk(false);
            setDetay()
            seterrorS({
              uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
            });

            setFormValue()

          })
          .catch(function (error) {
            setbuttonOk(false);
            seterrorS({
              uyari: { tip: "danger", error: true, message: error.response.data.message },
            });
            Hata(error);


            return;
          });



      }


    }
  }


  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />


      {(detay || detay > 0) && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <Tabs
                defaultActiveKey="genel"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="genel" title="Genel">
                  <div className="row">

                    <div className="form-group col-md-12 mb-3">
                      <label className="col-lg-3 control-label">Bayi :</label>

                      <Select value={formValue.bayiId && bayiList.filter(a => a.value === formValue.bayiId)}   placeholder="Seçiniz" onChange={e => selectChange('bayiId', e.value)} options={bayiList} name="bayiId" id="bayiId">
                      </Select>

                    </div>


                    <div className="form-group mb-4  col-md-9">
                      <label className="col-lg-3 control-label"> Ad Soyad:</label>
                      <div className="col-lg-12">
                        <input type="text" id="adSoyad" name="adSoyad" className="form-control" onChange={handleChange} value={formValue.adSoyad} />
                      </div>
                    </div>


                    <div className="form-group mb-4 ">
                      <label className="col-lg-3 control-label">Giriş Mail Adresi :</label>
                      <div className="col-lg-12">
                        <input type="email" id="lMail" name="lMail" className="form-control" onChange={handleChange} value={formValue.lMail} />
                      </div>
                    </div>



                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-3 control-label">Mail :</label>
                      <div className="col-lg-12">
                        <input type="email" id="mail" name="mail" className="form-control" onChange={handleChange} value={formValue.mail} />
                      </div>
                    </div>



                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-3 control-label">Telefon :</label>
                      <div className="col-lg-12">
                        <input type="phone" id="tel" name="tel" className="form-control" onChange={handleChange} value={formValue.tel} />
                      </div>
                    </div>

                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-3 control-label">Whatsapp :</label>
                      <div className="col-lg-12">
                        <input type="phone" id="wa" name="wa" className="form-control" onChange={handleChange} value={formValue.wa} />
                      </div>
                    </div>





                  </div>

                </Tab>
                <Tab eventKey="Detay" title="Detay">


                  <div className="form-group">
                    <label className="col-lg-12 control-label"> Metin:</label>
                    <div className="col-lg-12">

                      <SunEditor defaultValue={editor} onChange={seteditor} name="metin" setOptions={{
                        buttonList: [
                          // default
                          ['undo', 'redo'],
                          [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                          ['fontColor', 'hiliteColor', 'textStyle'],
                          ['removeFormat'],
                          ['outdent', 'indent'],
                          ['align', 'horizontalRule', 'list', 'lineHeight']
                        ]
                      }} />


                    </div>
                  </div>


                </Tab>
                <Tab eventKey="aciklama" title="Not Açıklama & Durum">
                  <div className="row">
                    <div className="form-group  mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Durum?:</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={formValue.durum} onChange={handleChange} name="durum" id="durum">
                          <option value={true}>Evet</option>
                          <option value={false}>Hayır</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Url :</label>
                      <div className="col-lg-12">
                        <input type="text" id="url" name="url" className="form-control" onChange={handleChange} value={formValue.url} />
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label className="col-lg-12 control-label">Açıklama :</label>
                      <div className="col-lg-12">
                        <textarea type="text" id="aciklama" name="aciklama" className="form-control" onChange={handleChange} value={formValue.aciklama}  ></textarea>
                      </div>
                    </div>

                  </div>


                </Tab>
              </Tabs>


            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay