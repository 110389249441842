import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import Select from 'react-select';

const BayiDanisman = ({ detay, setDetay, setData }) => {


    const [formValue, setFormValue] = useState()
    const [buttonOk, setbuttonOk] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });


    const [options, setOptions] = useState([]);
    const getOptions = (e) => {

        if (e.length > 2) {
            setIsLoading(true)


            userGetList('EB_Modul/selectOptions/' + detay.tip + '?q=' + e)
                .then((res) => {
                    setIsLoading(true);

                    setOptions(res.data);
                })
                .catch(
                    err => { setIsLoading(false); setOptions([]); }
                );
        } else { setIsLoading(false); setOptions([]); }
    }

    useEffect(() => {

        if (detay) {

            if (detay && detay.item && detay.item.value) {
                setFormValue((detay.item))
            } else {

                setFormValue({ label: '', value: '' })
            }

        } else {
            setFormValue({ label: '', value: '' })
        }


    }, [detay])


    const onSubmit = () => {

        if (detay) {

            setbuttonOk(true);
            var form_data = new FormData();
            form_data.append("id", formValue.value);
            form_data.append("tip", detay.tip);

            userPost("EB_FormListesi/Yonlendir/" + detay.id, form_data)
                .then((a) => {
                    setData(a.data);
                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });

                    setbuttonOk(false);
                    setDetay()
                    setFormValue()

                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });


        }
    }


    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >

                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            {(detay.tipB)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">


                            <div className="col-lg-3">
                                <strong className="control-label">icerik :</strong>
                            </div>
                            <div className="col-lg-9">

                                <Select className="basic-single"
                                    classNamePrefix="select"
                                    isLoading={isLoading}
                                    value={formValue}
                                    onChange={e => { console.log(e); setFormValue(e) }}
                                    options={options}
                                    onInputChange={e => getOptions(e)}
                                ></Select>

                            </div>





                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onSubmit()}>
                                    Ata
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>



                </Modal>
            )}

        </>
    )
}

export default BayiDanisman

