import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
import Listeler from './listeler';
import ModulSil from './modulSil'


import SunEditor from 'suneditor-react';

const ModulDetay = ({ detay, setDetay, setData }) => {


  const [formValue, setFormValue] = useState();

  const [buttonOk, setbuttonOk] = useState(false);

  const [liste, setListe] = useState([]);

  const [editor, seteditor] = useState();

  const [modulSil, setModulSil] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  const listChance = (i, val) => {

    liste[i] = { value: val, label: '' };
    setListe(liste);
    setFormValue(formValue)
  }

  useEffect(() => {

    if (detay) {

      userGetList("EB_Modul/Submoduldetay/" + detay.id)
        .then((a) => {


          setFormValue(a.data.modul);
          seteditor(a.data.modul.htmlicerik)

          if (a.data.liste.length > 0) {
            setListe(a.data.liste)
          }
          else { setListe([{ value: '', label: '' }]) }

          setbuttonOk(false)


        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])


  useEffect(() => {
    if (detay && formValue && (formValue.type === 'BA1' || formValue.type === 'DA1' || formValue.type === 'BL1')) {

      var dnckL = formValue.count - liste.length;

      if (liste.length > formValue.count) {

        setListe(liste.slice(0, formValue.count))

      } else if (dnckL > 0) {
        const arr = []
        for (let i = 1; i <= dnckL; i++) {
          arr.push({ value: '', label: '' });
        }
        setListe(liste.concat(arr));
      }
    }
  }, [formValue])


  const handleChange = (event) => {


    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }


  const onSubmit = () => {
    if (detay) {

      setbuttonOk(true);

      var form_data = new FormData();
      if (formValue.type === "HE1") { formValue.htmlicerik = editor; }


      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }



      if ((formValue.type === 'BA1' || formValue.type === 'DA1' || formValue.type === 'BL1') && liste.length > 0) {
        for (let i = 0; i < liste.length; i++) {
          console.log(liste[i])
          form_data.append("liste", liste[i].value);

        }
      }
      userPost("EB_Modul/Submodulguncelle/" + detay.id, form_data)
        .then((a) => {

          setData(a.data.liste);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });


        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && formValue && (
        <>

          <ModulSil detay={modulSil} setDetay={setModulSil} setData={setData} setMDetay={setDetay}  ></ModulSil>
          <Modal
            size="xl"
            show={detay ? true : false}
            onHide={() => setDetay()}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Detay
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              {formValue &&
                <Tabs
                  defaultActiveKey="genel"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="genel" title="Genel">
                    <div className="row">

                      <div className="form-group mb-4">
                        <label className="col-lg-3 control-label">Durum?:</label>
                        <div className="col-lg-12">
                          <select className="form-control" value={formValue.durum} onChange={handleChange} name="durum" id="durum">
                            <option value={true}>Evet</option>
                            <option value={false}>Hayır</option>
                          </select>
                        </div>
                      </div>

                  


                      <div className="form-group mb-4">
                        <label className="col-lg-3 control-label">Sütun :</label>
                        <div className="col-lg-12">
                          <select type="date" id="col" name="col" className="form-control" onChange={handleChange} value={formValue.col}>
                            {detay && detay.col && [...Array(parseInt(detay.col))].map((a, x) => <option key={x + 1}>{x + 1}</option>)}


                          </select>
                        </div>
                      </div>

                      <div className="mb-4 col-md-8">
                        <label className="col-lg-12 control-label">Başlık :</label>
                        <div className="col-lg-12">
                          <input type="text" id="mtitle" name="mtitle" className="form-control" onChange={handleChange} value={formValue.mtitle} />
                        </div>
                      </div>


                      <div className="mb-4 col-md-4">
                        <label className="col-lg-12 control-label"> Başlık Göster :</label>
                        <div className="col-lg-12">
                          <select className="form-control" defaultValue={formValue.mtitleG} onChange={handleChange} name="mtitleG" id="mtitleG">
                            <option value={true}>Evet</option>
                            <option value={false}>Hayır</option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-4 col-md-8">
                        <label className="col-lg-12 control-label">Açıklama :</label>
                        <div className="col-lg-12">
                          <input type="text" id="mdesc" name="mdesc" className="form-control" onChange={handleChange} value={formValue.mdesc} />
                        </div>
                      </div>


                      <div className="mb-4 col-md-4">
                        <label className="col-lg-12 control-label"> Açıklama Göster :</label>
                        <div className="col-lg-12">
                          <select className="form-control" defaultValue={formValue.mdescG} onChange={handleChange} name="mdescG" id="mdescG">
                            <option value={true}>Evet</option>
                            <option value={false}>Hayır</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <label className="col-lg-3 control-label">Sıra :</label>
                        <div className="col-lg-12">
                          <input type="text" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <label className="col-lg-3 control-label">Css :</label>
                        <div className="col-lg-12">
                          <input type="text" id="css" name="css" className="form-control" onChange={handleChange} value={formValue.css} />
                        </div>
                      </div>

                    </div>
                  </Tab>
                  <Tab eventKey="icerik" title="İçerik">

                    <div className="row">

                      {formValue && <>



                        {formValue.type === "HE1" &&
                          <>

                            <div className="form-group">
                              <label className="col-lg-3 control-label">Template Metni:</label>
                              <div className="col-lg-12">

                                <SunEditor defaultValue={editor} setContents={editor} name="htmlicerik" onChange={e => {
                                  seteditor(e)
                                }} setOptions={{
                                  buttonList: [
                                    // default
                                    ['undo', 'redo'],
                                    [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                    ['fontColor', 'hiliteColor', 'textStyle'],
                                    ['removeFormat'],
                                    ['outdent', 'indent'],
                                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                                    ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save', 'template'],
                                    ['-right', 'image', 'video', 'audio', 'link'],

                                  ]
                                }} />
                              </div>
                            </div></>
                        }

                        {(formValue.type === "TE1" || formValue.type === "JSON") && <div>
                          <div className="form-group mb-4">
                            <label className="col-lg-3 control-label">İçerik :</label>
                            <div className="col-lg-12">
                              <textarea type="text" id="htmlicerik" name="htmlicerik" className="form-control" onChange={handleChange} value={formValue.htmlicerik} />
                            </div>
                          </div>
                        </div>}

                        {(formValue.type === "BA1" || formValue.type === "DA1" || formValue.type === "BL1") &&
                          <>


                            <div className="mb-4 col-md-6">
                              <label className="col-lg-12 control-label">Adet :</label>
                              <div className="col-lg-12">

                                <select className="form-control" defaultValue={parseInt(formValue.count)} onChange={handleChange} name="count" id="count">
                                  {[...Array(parseInt(12))].map((a, x) => <option key={x + 1}>{x + 1}</option>)}

                                </select>
                              </div>
                            </div>



                            <div className="mb-4 col-md-6">
                              <label className="col-lg-12 control-label">Filtre :</label>
                              <div className="col-lg-12">
                                <select className="form-control" defaultValue={formValue.icTip} onChange={handleChange} name="icTip" id="icTip">
                                  <option value={"Puan"}>Puana göre</option>
                                  <option value={"Yeni"}>Yeni Eklenen</option>
                                  <option value={"Filtre"}>Filtre</option>

                                </select>
                              </div>
                            </div>



                            <div className="mb-4 col-md-6">
                              <label className="col-lg-12 control-label">Gösterim Tip :</label>
                              <div className="col-lg-12">
                                <select className="form-control" defaultValue={formValue.gosterimT} onChange={handleChange} name="gosterimT" id="gosterimT">
                                  <option value={'List'}>Liste</option>
                                  <option value={'Carousel '}>Carousel </option>
                                </select>
                              </div>
                            </div>


                            <div className="mb-4 col-md-6">
                              <label className="col-lg-12 control-label">Sıra Adet :</label>
                              <div className="col-lg-12">
                                <select className="form-control" defaultValue={formValue.gosterimC} onChange={handleChange} name="gosterimC" id="gosterimC">
                                  <option value={6}>2</option>
                                  <option value={4}>3 </option>
                                  <option value={3}>4 </option>
                                  <option value={2}>6 </option>
                                </select>
                              </div>
                            </div>

                            {formValue.icTip === "Filtre" &&
                              <Listeler type={formValue.type} liste={liste} setListe={setListe} listChance={listChance}> </Listeler>}
                          </>
                        }




                      </>}








                    </div>

                  </Tab>
                </Tabs>

              }


            </Modal.Body>
            <Modal.Footer>
              {!buttonOk && (
                <>



                  <Button variant="secondary" onClick={() => setDetay()}>
                    Kapat
                  </Button>

                  <Button variant="danger" onClick={() => setModulSil({ title: formValue.mtitle, id: detay.id })}>
                    Sil
                  </Button>

                  <Button variant="success" onClick={() => onSubmit()}>
                    Onayla
                  </Button>
                </>
              )}
              {buttonOk && <LoadSpin />}
            </Modal.Footer>
          </Modal></>

      )}

    </>
  )
}

export default ModulDetay