
export const getFormattedPrice = (price) => {
    try {
        return `${price.toFixed(2)}`;
    } catch (error) {
        return "-";
    }


};

export const getFormatDatetime = (date) => {

    try {
        // console.log("g :"+date);

        const datee = new Date(date);
 
        return datee.toLocaleDateString()

    } catch (error) {
        return "Hatalı Tarih."
    }

};
