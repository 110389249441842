import React, { Component } from "react";
import Footer from './footer'
import Header from './header'

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

    }

    componentDidUpdate() {

    }

    // $(window).on('load', function () {
    //     setTimeout(function () {
    //       $('.loader-wrapper').fadeOut('slow');
    //       $('.box').addClass('text-affect');
    //     }, 1000);
    //     $('.loader-wrapper').remove('slow');
    //   });


    render() {
        return (
            <>
              <div id="layout-wrapper">
              <Header></Header>
        <div class="main-content">
          <div class="page-content"> {this.props.children}</div>
          <Footer />
        </div>
      </div>
               

 

            </>
        );
    }
}


export default Layout