import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userDell } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';


const Sil = ({ detay, setDetay, setData }) => {
    useEffect(() => {
        setbuttonOk(false);
    }, [detay])

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    const onRemove = () => {
        console.log(detay)
        if (detay && detay.fn.id) {
            setbuttonOk(true);
           
            console.log("EB_Menu/MenuSil/" + detay.fn.id)
            userDell("EB_Menu/MenuSil/" + detay.fn.id)
                .then((a) => {

                    setbuttonOk(false);

                    seterrorS({
                        uyari: { error: true, message: "İşlem Başarılı.", tip: "success" },
                    });
                    setData(a.data);
                    setDetay();
                })
                .catch(function (error) {
                    console.log(error);
                    seterrorS({
                        uyari: { tip: "error", error: true, message: error.response.data.message },
                    });
                    Hata(error);
                    setbuttonOk(false);

                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="md"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Sil
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body><strong> {detay.fn.uId && <>{detay.u.ad}</>}
                        {detay.fn.sid && <>{detay.p.title}</>}</strong> menü bilgisi silinsin mi?</Modal.Body>{" "}
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Kapat
                                </Button>
                                <Button variant="danger" onClick={() => onRemove()}>
                                    Sil
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Sil