import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Hatamodal from '../../functionList/hatamodal';
import {  userGetList, userPost } from '../../functionList/axiosComp'
import LoadSpin, { Spin } from '../../functionList/loadSpin';
import Hata from '../../functionList/hata';
import SunEditor from 'suneditor-react';
 

const SeoAyarlari = () => {

    const [isButton, setIsButton] = useState(false);
    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    const [data, setData] = useState()


    // useEffect(() => {
    //     console.log(data);
    // }, [data])


    useEffect(() => {
        userGetList("EB_Genel/Index")
            .then((a) => {

                setData(a.data);
                setIsButton(false);
            })
            .catch(function (error) {
                Hata();
                seterrorS({
                    uyari: { error: true, message: error.response.data.message, tip: "error" },
                });
                setData();
                setIsButton(false);
                return;
            });
    }, []);



    const handleChange = (event) => {

        if (event && event.target) {

            setData({
                ...data,
                [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
            });

        } else {
            setData({
                ...data,
                iletisim: event
            });
        }

    }


    const onSubmit = () => {

        setIsButton(true);

        var form_data = new FormData();

        for (var key in data) {
            form_data.append(key, data[key]);
        }


        userPost("EB_Genel/Guncelle/", form_data)
            .then((a) => {
                setData(a.data);
                setIsButton(false);

                seterrorS({
                    uyari: {
                        error: true,
                        message: "Bilgiler güncellendi",
                        tip: "success",
                    },
                });
            })
            .catch(function (error) {
                setIsButton(false);
                seterrorS({
                    uyari: {
                        tip: "error",
                        error: true,
                        message: error.response.data.message,
                    },
                });


                return;
            });



    }


    return (
        <div class="container-fluid">
            <div className='card'>
                <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

                <div className="card-body">
                    <h4>Seo ve İletişim Footer  Bilgileri</h4>
                    <div className="row">

                        {data &&
                            <form className="form-horizontal" id="form" name="form" autocomplete="off"  >
                                <h6>Seo  Bilgileri</h6>
                                <div className="col-md-12">


                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Başlık :</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="title" name="title" className="form-control" onChange={handleChange} value={data.title} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Meta Key:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="metaKey" name="metaKey" className="form-control" onChange={handleChange} value={data.metaKey} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Meta Des:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="metaDes" name="metaDes" className="form-control" onChange={handleChange} value={data.metaDes} />
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Copyright:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="copyright" name="copyright" className="form-control" onChange={handleChange} value={data.copyright} />
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Analytics:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="analytics" name="analytics" className="form-control" onChange={handleChange} value={data.analytics} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Metrica:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="metrica" name="metrica" className="form-control" onChange={handleChange} value={data.metrica} />
                                        </div>
                                    </div>
                                    <hr></hr>


                                    <h6>İletişim   Bilgileri</h6>



                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Açıklama:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="descc" name="descc" className="form-control" onChange={handleChange} value={data.descc} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Phone:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="phone" name="phone" className="form-control" onChange={handleChange} value={data.phone} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Email:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="email" name="email" className="form-control" onChange={handleChange} value={data.email} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Adres:</label>
                                        <div className="col-lg-12">
                                            <input type="text" id="adres" name="adres" className="form-control" onChange={handleChange} value={data.adres} />
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">Durum?:</label>
                                        <div className="col-lg-12">
                                            <select className="form-control" defaultValue={data.state} onChange={handleChange} name="state" id="state">
                                                <option value={true}>Evet</option>
                                                <option value={false}>Hayır</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr></hr>


                                    <div className="form-group">
                                        <label className="col-lg-3 control-label">İletişim Metni:</label>
                                        <div className="col-lg-12">
                                           
                                      
                                            <SunEditor defaultValue={data.iletisim} name="templateIcerik" onChange={e => {
                    setData({
                      ...data,
                      ['iletisim']: e
                    })
                  }} setOptions={{
                    buttonList: [
                      // default
                      ['undo', 'redo'],
                      [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                      ['fontColor', 'hiliteColor', 'textStyle'],
                      ['removeFormat'],
                      ['outdent', 'indent'],
                      ['align', 'horizontalRule', 'list', 'lineHeight'],
                      ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save', 'template'],
                      ['-right', 'image', 'video', 'audio', 'link'],

                    ]
                  }} />
                                      
                                      
                                        </div>
                                    </div>



                                </div>

                                <div className="form-group mt-2">
                                    <div className="text-end">

                                        {isButton && <Button className="btn btn-success m-2" disabled ><Spin /> Bekleyiniz </Button>}

                                        {!isButton && <Button className="btn btn-success m-2" onClick={() => onSubmit()}>Güncelle  </Button>}

                                    </div>
                                </div>





                            </form>}

                        {!data && <LoadSpin></LoadSpin>}

                    </div>
                </div>

            </div></div>



    )
}

export default SeoAyarlari