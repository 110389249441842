import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


const Detay = ({ detay, setDetay, setData }) => {


  const [formValue, setFormValue] = useState()
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {

    if (detay) {
      userGetList("EB_MailTemplate/Detay/" + detay)
        .then((a) => {
          setFormValue(a.data);

          setbuttonOk(false)


        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])





  const handleChange = (event) => {


    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }

  const onSubmit = () => {
    if (detay) {
      if (formValue.templateAd.length < 3) {
        seterrorS({
          uyari: { error: true, message: "Template adı giriniz.", tip: "danger" },
        });

        return;
      }

      setbuttonOk(true);
      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      userPost("EB_MailTemplate/Güncelle/" + detay, form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });


        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="form-group">
                <label className="col-lg-3 control-label">Durum?:</label>
                <div className="col-lg-12">
                  <select className="form-control" defaultValue={formValue.durum} onChange={handleChange} name="durum" id="durum">
                    <option value={true}>Evet</option>
                    <option value={false}>Hayır</option>
                  </select>
                </div>
              </div>


              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Template Ad :</label>
                <div className="col-lg-12">
                  <input type="text" id="templateAd" name="templateAd" className="form-control" onChange={handleChange} value={formValue.templateAd} />
                </div>
              </div>


              <div className="form-group">
                <label className="col-lg-3 control-label">Template Metni:</label>
                <div className="col-lg-12">



                  <SunEditor defaultValue={formValue.templateIcerik} name="templateIcerik" onChange={e => {
                    setFormValue({
                      ...formValue,
                      ['templateIcerik']: e
                    })
                  }} setOptions={{
                    buttonList: [
                      // default
                      ['undo', 'redo'],
                      [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                      ['fontColor', 'hiliteColor', 'textStyle'],
                      ['removeFormat'],
                      ['outdent', 'indent'],
                      ['align', 'horizontalRule', 'list', 'lineHeight'],
                      ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save', 'template'],
                      ['-right', 'image', 'video', 'audio', 'link'],

                    ]
                  }} />
                </div>
              </div>



            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay