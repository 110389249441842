import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Yeni = ({ detay, setDetay, setData }) => {

    const [formValue, setFormValue] = useState({
        ad: '', link: '', sira: 5

    })
    const [preview, setPreview] = useState()
    const [gorsel, setGorsel] = useState();

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });



    useEffect(() => {
        if (!gorsel) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(gorsel)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [gorsel])


  

    const onEkle = () => {
        if (detay) {

  
            setbuttonOk(true);
            var form_data = new FormData();

            form_data.append("file", gorsel);
 
            userPut("EB_BannerManset/GorselEkle", form_data)
                .then((a) => {
                    setData(a.data.liste);
                    setbuttonOk(false);

                    seterrorS({
                        uyari: { error: true, message: a.data.message, tip: "success" },
                    });
                    setDetay(); setGorsel() 

                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div class="mb3 row">
                                <div class="col-sm-12 text-center">
                                    {gorsel && <img className="img-fluid " style={{ maxHeight: "500px" }} src={preview} />}
                                </div>
                            </div>

                            <div class="mb3 row">
                                <label class="form-label col-sm-2 ">Foto</label>
                                <div class="col-sm-10">
                                    <input
                                        type="file"
                                        name="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={e => e.target.files?.length > 0 && setGorsel(e.target.files[0])}
                                    />
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => { setDetay(); setGorsel() }}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni