import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
import { Sehir, Ilce, Semt, Mahalle } from '../../../functionList/citys';

import SunEditor from 'suneditor-react';


const Detay = ({ detay, setDetay, setData }) => {


  const [editor, seteditor] = useState('');
  const [formValue, setFormValue] = useState()
  const [buttonOk, setbuttonOk] = useState(false);

  const [SehirList, setSehirList] = useState([]);
  const [IlceList, setIlceList] = useState([]);
  const [SemtList, setSemtList] = useState([]);
  const [MahalleList, setMahalleList] = useState([]);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });



  function ilceGetir(id) {

    setFormValue({
      ...formValue,
      ['mah']: null
    });

    if (id !== 0) {

      Ilce(id).then(a => { setIlceList(a.data) });

      setSemtList([])
      setMahalleList([])
    } else {
      setIlceList()
      setSemtList([])
      setMahalleList([])


    }

  }

  function semtGetir(id) {

    setFormValue({
      ...formValue,
      ['mah']: null
    });

    if (id !== 0) {

      Semt(id).then(a => { setSemtList(a.data); });

      setMahalleList([])
    } else {

      setSemtList([])
      setMahalleList([])
    }


  }

  function mahGetir(id) {
    setFormValue({
      ...formValue,
      ['mah']: null
    });
    if (id !== 0) {
      Mahalle(id).then(a => setMahalleList(a.data));
    } else {
      setMahalleList([]);
    }

  }



  useEffect(() => {

    Sehir().then(a => setSehirList(a.data));

    if (detay) {

      userGetList("EB_Bayiler/GDetay/" + detay)
        .then((a) => {

          setFormValue(a.data);
          seteditor(a.data.detay)
          setbuttonOk(false)

          Ilce(a.data.cityId).then(b => { setIlceList(b.data) });
          Semt(a.data.townId).then(b => { setSemtList(b.data); });
          Mahalle(a.data.districtId).then(b => { setMahalleList(b.data) });



        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])


  const handleChange = (event) => {


    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }




  const onSubmit = () => {

    if (detay) {
      console.log(editor)

      if (!formValue.mah || formValue.mah == null) {
        seterrorS({
          uyari: { error: true, message: "Şehir - İlçe - Semt- Mahalle boş geçilemez.", tip: "danger" },
        });
        return;
      } else if (formValue.bayiAdi.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Bayi adı giriniz.", tip: "danger" },
        });

        return false;
      }
      else if (!formValue.lMail) {

        seterrorS({
          uyari: { error: true, message: " Giriş email  adresi giriniz.", tip: "danger" },
        });
        return;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValue.lMail)) {
        seterrorS({
          uyari: { error: true, message: "Geçerli 'Giriş Mail'   adresi giriniz.", tip: "danger" },
        });
        return;
      }
      else {

        setbuttonOk(true);
        var form_data = new FormData();

        formValue.detay = editor; 
        for (var key in formValue) {
          form_data.append(key, formValue[key]);
        }
 

        userPost("EB_Bayiler/Guncelle/" + detay, form_data)
          .then((a) => {
            setData(a.data);
            setbuttonOk(false);
            setDetay()
            seterrorS({
              uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
            });

            setFormValue({
              bayiAdi: '', mah: null, aciklama: '', adres: '', mail: "", Lmail: "", puan: "0", site: "", telefon: "", wa: "",

            })

          })
          .catch(function (error) {
            setbuttonOk(false);
            seterrorS({
              uyari: { tip: "danger", error: true, message: error.response.data.message },
            });
            Hata(error);


            return;
          });



      }


    }
  }


  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />


      {(detay || detay > 0) && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <Tabs
                defaultActiveKey="genel"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="genel" title="Genel">
                  <div className="row">



                    <div className="form-group col-md-3">
                      <label className="col-lg-12 control-label">Şehir :</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={formValue.cityId} onChange={(e) => { ilceGetir(e.target.value) }} name="sehir" id="sehir">
                          <option value={0}>Seçiniz</option>
                          {SehirList && SehirList.map((a) =>
                            <option value={a.id}>{a.yer}</option>)}
                        </select>
                      </div>
                    </div>

                    <div className="form-group col-md-3">
                      <label className="col-lg-12 control-label">İlçe :</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={parseInt(formValue.townId)} onChange={(e) => { semtGetir(e.target.value) }} name="ilce" id="ilce">
                          <option value={0}>Seçiniz</option>
                          {IlceList && IlceList.map((a) =>
                            <option value={a.id}>{a.yer}</option>)}

                        </select>
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label className="col-lg-12 control-label">Semt :</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={parseInt(formValue.districtId)} onChange={(e) => { mahGetir(e.target.value) }} name="Semt" id="Semt">
                          <option value={0}>Seçiniz</option>
                          {SemtList && SemtList.map((a) =>
                            <option value={a.id}>{a.yer}</option>)}

                        </select>
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label className="col-lg-12 control-label">Mahalle :</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={formValue.mah} onChange={handleChange} name="mah" id="mah">
                          <option value={0} selected={formValue.mah === null}>Seçiniz</option>
                          {MahalleList && MahalleList.map((a) =>
                            <option value={a.id} selected={formValue.mah === a.id}>{a.yer}</option>)}

                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="col-lg-12 control-label">Adres :</label>
                      <div className="col-lg-12">
                        <input type="text" id="adres" name="adres" className="form-control" onChange={handleChange} value={formValue.adres} />
                      </div>
                    </div>



                    <div className="form-group mb-4  col-md-6">
                      <label className="col-lg-12 control-label">Bayi Adı :</label>
                      <div className="col-lg-12">
                        <input type="text" id="bayiAdi" name="bayiAdi" className="form-control" onChange={handleChange} value={formValue.bayiAdi} />
                      </div>
                    </div>


                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Giriş Mail Adresi :</label>
                      <div className="col-lg-12">
                        <input type="email" id="lMail" name="lMail" className="form-control" onChange={handleChange} value={formValue.lMail} />
                      </div>
                    </div>



                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Mail :</label>
                      <div className="col-lg-12">
                        <input type="email" id="mail" name="mail" className="form-control" onChange={handleChange} value={formValue.mail} />
                      </div>
                    </div>


                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Site :</label>
                      <div className="col-lg-12">
                        <input type="text" id="site" name="site" className="form-control" onChange={handleChange} value={formValue.site} />
                      </div>
                    </div>

                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Telefon :</label>
                      <div className="col-lg-12">
                        <input type="phone" id="telefon" name="telefon" className="form-control" onChange={handleChange} value={formValue.telefon} />
                      </div>
                    </div>

                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Whatsapp :</label>
                      <div className="col-lg-12">
                        <input type="phone" id="wa" name="wa" className="form-control" onChange={handleChange} value={formValue.wa} />
                      </div>
                    </div>



                    <div className="form-group mb-4 col-md-4">
                      <label className="col-lg-12 control-label">Ofis Telefon 1:</label>
                      <div className="col-lg-12">
                        <input type="phone" id="tel1" name="tel1" className="form-control" onChange={handleChange} value={formValue.tel1} />
                      </div>
                    </div>

                    <div className="form-group mb-4 col-md-4">
                      <label className="col-lg-12 control-label">Ofis Telefon 2:</label>
                      <div className="col-lg-12">
                        <input type="phone" id="tel2" name="tel2" className="form-control" onChange={handleChange} value={formValue.tel2} />
                      </div>
                    </div>

                    <div className="form-group mb-4 col-md-4">
                      <label className="col-lg-12control-label">Ofis Telefon 3:</label>
                      <div className="col-lg-12">
                        <input type="phone" id="tel3" name="tel3" className="form-control" onChange={handleChange} value={formValue.tel3} />
                      </div>
                    </div>


                  </div>

                </Tab>
                <Tab eventKey="Detay" title="Detay">


                  <div className="form-group">
                    <label className="col-lg-12 control-label"> Metin:</label>
                    <div className="col-lg-12">

                      <SunEditor defaultValue={editor} onChange={seteditor} name="metin" setOptions={{
                        buttonList: [
                          // default
                          ['undo', 'redo'],
                          [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                          ['fontColor', 'hiliteColor', 'textStyle'],
                          ['removeFormat'],
                          ['outdent', 'indent'],
                          ['align', 'horizontalRule', 'list', 'lineHeight']
                        ]
                      }} />


                    </div>
                  </div>


                </Tab>
                <Tab eventKey="aciklama" title="Not Açıklama & Durum">
                  <div className="row">
                    <div className="form-group  mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Durum?:</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={formValue.durum} onChange={handleChange} name="durum" id="durum">
                          <option value={true}>Evet</option>
                          <option value={false}>Hayır</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-4 col-md-6">
                      <label className="col-lg-12 control-label">Url :</label>
                      <div className="col-lg-12">
                        <input type="text" id="url" name="url" className="form-control" onChange={handleChange} value={formValue.url} />
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label className="col-lg-12 control-label">Açıklama :</label>
                      <div className="col-lg-12">
                        <textarea type="text" id="aciklama" name="aciklama" className="form-control" onChange={handleChange} value={formValue.aciklama}  ></textarea>
                      </div>
                    </div>

                  </div>


                </Tab>
              </Tabs>


            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay