import { getList } from "./axiosComp"

const Sehir = () => {
    return getList("api/main/Sehir");
}

const Ilce = (id) => { 
    return getList("api/main/Ilce/" + id);
}

const Semt = (id) => {
    return getList("api/main/Semt/" + id);
}

const Mahalle = (id) => {
    return getList("api/main/Mahalle/" + id);
}

export { Sehir, Ilce, Semt, Mahalle }