import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
import { ModulSelect, ClassSize } from '../../../functionList/moduleSize';


const Detay = ({ detay, setDetay, setData }) => {


  const [formValue, setFormValue] = useState()
  const [cListe, setCListe] = useState()
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {
    if (detay) {
      userGetList("EB_Modul/ModulDetay/" + detay)
        .then((a) => {

          console.log(a.data);
          setCListe(a.data.liste)
          setFormValue(a.data.modul);
          setbuttonOk(false)

        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])





  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });
  }

  const onSubmit = () => {
    if (detay) {

      if (formValue.title.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Grup başlığı giriniz.", tip: "danger" },
        });

        return;
      }

      setbuttonOk(true);
      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }


      const selectList = document.getElementsByClassName("msizecss");

      const coll = ([...Array(parseInt(formValue.col))])
      console.log(coll)
      coll.forEach((a, i) => {
        const selFlList = (Array.from(selectList).filter(a => a.name === "col" + (i + 1)));

        let alValue = '';
        selFlList.forEach((a) => {

          alValue = alValue + ' ' + a.value;
        })

        console.log(alValue);

        form_data.append("col-" + (i + 1), alValue);


      });



      userPost("EB_Modul/ModulGuncelle/" + detay, form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });

        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="form-group">
                <label className="col-lg-3 control-label">Durum?:</label>
                <div className="col-lg-12">
                  <select className="form-control" defaultValue={formValue.durum} onChange={handleChange} name="durum" id="durum">
                    <option value={true}>Evet</option>
                    <option value={false}>Hayır</option>
                  </select>
                </div>
              </div>


              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Başlık :</label>
                <div className="col-lg-12">
                  <input type="text" id="title" name="title" className="form-control" onChange={handleChange} value={formValue.title} />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Sıra :</label>
                <div className="col-lg-12">
                  <input type="text" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Css :</label>
                <div className="col-lg-12">
                  <input type="text" id="css" name="css" className="form-control" onChange={handleChange} value={formValue.css} />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Bitiş Tarihi :</label>
                <div className="col-lg-12">
                  <input type="date" id="bitisTarihi" name="bitisTarihi" className="form-control" onChange={handleChange} value={formValue.bitisTarihi} />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Sütun Sayısı :</label>
                <div className="col-lg-12">
                  <select type="date" id="col" name="col" className="form-control" defaultValue={formValue.col} onChange={handleChange} value={formValue.col}>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                  </select>
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Genişlik:  </label>
                <div className="col-lg-12">
                  <select className="form-control" value={formValue.container} onChange={handleChange} name="container" id="container">
                    <option value={true}>Normal</option>
                    <option value={false}>Full Genişlik</option>
                  </select>
                </div>
              </div>

              <div className="form-group mb-4">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {ClassSize.map((a) => <th>{a.name}<br />
                        <span className='font-weight-light'>{a.size}</span></th>)}
                    </tr>
                  </thead>
                  <tbody>


                    {formValue && [...Array(parseInt(formValue.col))].map((x, t) => <tr> <th>{t + 1}</th> {ClassSize.map((a, i) => <th>   <ModulSelect defaultValue={cListe.length > (t) && cListe[t][i]} name={"col" + (t + 1)} val={a.val} className={"form-control msizecss "}></ModulSelect>  </th>)}
                    </tr>)}

                  </tbody>
                </table>



              </div>




            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay