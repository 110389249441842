import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getList, put } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Yeni = ({ detay, setDetay, setData }) => {

  const [formValue, setFormValue] = useState({
    KatAd: '', Ustkat: 0, sira: 5,
  })

  const [katSelect, setKatSelect] = useState();
  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {
    getList("EB_Kategoriler/katSelect")
      .then((a) => { 
        setKatSelect(a.data);
      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });

        return;
      });
  }, []);



  const handleChange = (event) => {

    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }

  const onEkle = () => {
    if (detay) {


      if (formValue.KatAd.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Kategori adı giriniz.", tip: "danger" },
        });

        return;
      }

 
      setbuttonOk(true);
      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      put("EB_Kategoriler/Yeni", form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });
          setDetay();
          setFormValue({
            KatAd: '', Ustkat: 0, sira: 5,

          })

        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Yeni
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="form-group">
                <label className="col-lg-3 control-label">Üst Kategori:</label>
                <div className="col-lg-12">
                  <select className="form-control" defaultValue={formValue.Ustkat} onChange={handleChange} name="Ustkat" id="Ustkat">

                    {katSelect && katSelect.map((a) =>
                      <option value={a.katId}>{a.katAd}</option>)}

                  </select>
                </div>
              </div>


              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Kategori Adı :</label>
                <div className="col-lg-12">
                  <input type="text" id="KatAd" name="KatAd" className="form-control" onChange={handleChange} value={formValue.KatAd} />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Sıra :</label>
                <div className="col-lg-12">
                  <input type="number" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onEkle()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Yeni