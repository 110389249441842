import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userGetList, userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Yeni = ({ detay, setDetay, setData }) => {

    const [formValue, setFormValue] = useState({
        url: '', sid: '', sira: 5, icerik: "0"
    })

    const [buttonOk, setbuttonOk] = useState(false);
    const [glist, setGlist] = useState();

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });


    useEffect(() => {

        if (detay) {
            userGetList("EB_Menu/sayfalarUrller")
                .then((a) => {

                    console.log(a.data);
                    setGlist(a.data);
                    setbuttonOk(false)

                })
                .catch(function (error) {
                    Hata(error)
                    seterrorS({
                        uyari: { tip: "error", error: true, message: error.response.data.message },
                    });
                    setbuttonOk(false)

                    return;
                });
        }

    }, [detay])



    const handleChange = (event) => {

        if ([event.target.name] == "icerik") {

            setFormValue(e => ({
                ...formValue,
                sid: '',
                url: '',
                icerik: event.target.value
            }));

        } else {

            setFormValue({
                ...formValue,
                [event.target.name]: event.target.value
            });

        }
    }

    const onEkle = () => {
        if (detay) {

            var sira = (isNaN(parseInt(formValue.sira)));

            if (sira && sira > 0) {

                seterrorS({
                    uyari: { error: true, message: "Sıra numarası giriniz.", tip: "danger" },
                });

            } else if (Number(formValue.icerik) === 2 || Number(formValue.icerik) === 1) {




                setbuttonOk(true);
                var form_data = new FormData();

                for (var key in formValue) {
                    form_data.append(key, formValue[key]);
                }

                userPut("EB_Menu/MenuYeni", form_data)
                    .then((a) => {
                        setData(a.data);
                        setbuttonOk(false);

                        seterrorS({
                            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                        });
                        setDetay();
                        setFormValue({
                            url: '', sid: '', sira: 5, icerik: "0"
                        })

                    })
                    .catch(function (error) {
                        setbuttonOk(false);
                        seterrorS({
                            uyari: { tip: "danger", error: true, message: error.response.data.message },
                        });
                        Hata(error);


                        return;
                    });


            } else {

                seterrorS({
                    uyari: { error: true, message: "İçerik seçiniz", tip: "danger" },
                });
            }


        }

    }

    return (
        <>
            <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">


                            <div className="form-group">
                                <label className="col-lg-3 control-label">İçerik:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.icerik} onChange={handleChange} name="icerik" id="icerik">
                                        <option value={0}>Seçiniz</option>
                                        <option value={1}>Url</option>
                                        <option value={2}>Sayfa</option>
                                    </select>
                                </div>
                            </div>


                            {formValue.icerik === "0" &&
                                <div  >
                                    <h4 className='secimm'>İçerik Tipi Seçiniz</h4>
                                </div>}


                            {formValue.icerik === "1" && <div> <div className={"form-group secimm"}>
                                <label className="col-lg-3 control-label">Urller:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.url} onChange={handleChange} name="url" id="url">
                                        <option value="0">Seçiniz</option>
                                        {glist && glist.url.map((a) =>
                                            <option value={a.id}>{a.ad}</option>
                                        )}


                                        {/* <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option> */}
                                    </select>
                                </div>
                            </div></div>}

                            {formValue.icerik === "2" && <div> <div className={"form-group secimm"}>
                                <label className="col-lg-3 control-label">Sayfalar:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.sid} onChange={handleChange} name="sid" id="sid">
                                        <option value="0">Seçiniz</option>
                                        {glist && glist.sayfalar.map((a) =>
                                            <option value={a.id}>{a.title}</option>
                                        )}
                                        {/* <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option> */}
                                    </select>
                                </div>
                            </div></div>}



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Sıra :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk &&
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Kapat
                                </Button>
                                {(formValue.icerik === "1" || formValue.icerik === "2") && (<Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>)}
                            </>
                        }
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni