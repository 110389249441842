import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import LoadSpin from '../functionList/loadSpin'
import Hatamodal from '../functionList/hatamodal'
import { post } from '../functionList/axiosComp'
import { useDispatch } from 'react-redux';
import { userLogin } from '../redux/redux';

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [btnLoading, setbtnLoading] = useState(false);
    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    const [formValue, setformValue] = useState({
        kad: "",
        sifre: "",
    });

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value,
        });
    }


    const onSubmit = () => {
        setbtnLoading(true);

        var form_data = new FormData();

        form_data.append("kad", formValue.kad);
        form_data.append("pass", formValue.sifre);

        post("authe/singin", form_data)
            .then((a) => {

                var token = a.data;
           
                (dispatch(userLogin(
                   token
                )).then(() => {
                    navigate("/index");
                }));



            })
            .catch(function (error) {
                console.log(error);
                seterrorS({
                    uyari: {
                        error: true,
                        message: error.response.data.message,
                        tip: "error",
                    },
                });
                setbtnLoading(false);
                return;
            });


    }
    return (
        <div class="account-bg">
            <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>
            <div class="account-pages my-5 pt-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6 col-xl-5">
                            <div class="card">
                                <div class="card-body">
                                    <div class="text-center mt-4">
                                        <div class="mb-3">
                                            <a href="#" class="auth-logo">
                                                <img src="/images/logo-dark.png" height="60" class="logo-dark mx-auto" alt="" />

                                            </a>

                                        </div> {!btnLoading ?
                                            <div class="p-3">
                                                <h4 class="font-size-18 text-muted mt-2 text-center">Hoşgeldiniz !</h4>
                                                <p class="text-muted text-center mb-4">Lütfen kullanıcı bilgilerinizi giriniz.</p>

                                                <form class="form-horizontal" method="post" autocomplete="off" onSubmit={onSubmit}>

                                                    <div class="mb-3 text-start">
                                                        <label class="form-label" for="username">Kullanıcı Adı veya Email</label>
                                                        <input type="text" class="form-control" name="kad" placeholder="Kullanıcı Adı veya Email" value={formValue.kad}
                                                            onChange={handleChange} />
                                                    </div>

                                                    <div class="mb-3  text-start">
                                                        <label class="form-label" for="userpassword">Şifre</label>
                                                        <input type="password" class="form-control" name="sifre" placeholder="Enter password" value={formValue.sifre}
                                                            onChange={handleChange} />
                                                    </div>

                                                    <div class="mb-3 row   text-end">


                                                        <div class="col-sm-12 ">
                                                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit">
                                                                Giriş
                                                            </button>
                                                        </div>
                                                    </div>



                                                </form>

                                            </div>
                                            : <>
                                                <LoadSpin></LoadSpin>
                                            </>}
                                    </div>
                                </div>


                            </div>  <div class="row">
                                <div class="col-12 mt-4">
                                    <Link to="/forget-password" className="text-muted">   <i class="mdi mdi-lock"></i> Şifremi Unuttum</Link>

                                </div>
                            </div>
                            <div class="mt-5 text-center">

                                <p>
                                    ©
                                    <script>document.write(new Date().getFullYear())</script>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;