import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import SweetAlert from 'react-bootstrap-sweetalert'

const Hatamodal = ({ hata, seterrorS }) => {
  const [dHtShow, setdHtShow] = useState(false);
  const [htmodelshow, sethtmodelshow] = useState(false);

  useEffect(() => {
    if (hata.uyari.error) {
      sethtmodelshow(true);
    }
  }, [hata]);

  return (
    <>
   
      {hata.uyari.tip && hata.uyari.tip === "success" && (
        <SweetAlert
          success
          title="Başarılı"
          onConfirm={() => {
            sethtmodelshow(false);
            seterrorS({
              uyari: { error: false, message: "", tip: null },
            });
          }}
        >
          {hata && hata.uyari.message}
        </SweetAlert>
      )}
      {hata.uyari.tip && hata.uyari.tip === "danger" && (
        <SweetAlert
          warning
          title="Uyarı"
          onConfirm={() => {
            sethtmodelshow(false);
            seterrorS({
              uyari: { error: false, message: "", tip: null },
            });
          }}
        >
          {hata && hata.uyari.message}
        </SweetAlert>
      )}
      {hata.uyari.tip && hata.uyari.tip === "error" && (
        <SweetAlert
          error
          title="Hata"
          onConfirm={() => {
            sethtmodelshow(false);
            seterrorS({
              uyari: { error: false, message: "", tip: null },
            });
          }}
        >
          {hata && hata.uyari.message}
        </SweetAlert>
      )}

      {hata.uyari.tip === null && (
        <Modal
          size="sm"
          show={htmodelshow}
          onHide={() => {
            sethtmodelshow(false);
            seterrorS({
              uyari: { error: false, message: "", tip: null },
            });
          }}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">Uyarı</Modal.Title>
          </Modal.Header>
          <Modal.Body>{hata && hata.uyari.message}</Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Hatamodal;
