import React, { useState, useEffect } from 'react'
import {   userGetList } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import Detay from './components/detay';
import Sil from './components/sil';
import Yeni from './components/yeni';

const MailTemplate = () => {
  const [data, setData] = useState();

  const [yeni, setYeni] = useState();
  const [detay, setDetay] = useState();
  const [sil, setSil] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });



  useEffect(() => {
    userGetList("EB_MailTemplate/index")
      .then((a) => {
        console.log(a.data);
        setData(a.data);
      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
        setData();
        return;
      });
  }, []);






  return (
    <div class="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

      <Yeni detay={yeni} setDetay={setYeni} setData={setData}></Yeni>

      <Detay detay={detay} setDetay={setDetay} setData={setData}></Detay>
      <Sil detay={sil} setDetay={setSil} setData={setData} ></Sil>


      <div className='card'>
        <div className="card-body">
          <h4>Mail Template Ayarları</h4>
          <p>Gönderilecek tüm mail içerik tasarımlarını bu alandan yönetebilirsiniz.</p>

          <div className="text-end">
            <button onClick={() => setYeni(true)} className="btn btn-info">
              <i className="fas fa-plus"></i> Yeni
            </button>
          </div>


          <div className="row">

            {data &&
              <>
                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th width="90">#</th>
                        <th>Template</th>
                        <th className='text-center' width="120">Durum</th>
                        <th className='text-center' width="120">Sistem Template</th>
                        <th className='text-center' width="120">İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ?
                        data.map((a, index) => (
                          <tr key={a.id}  >
                            <td>{index + 1}</td>
                            <td>{a.templateAd}</td>
                            <td className='text-center'>{a.durum ? (
                              <i className="fa fa-check check"></i>
                            ) : (
                              <i className="fa fa-times times"></i>
                            )}</td>
                            <td className='text-center'>{a.slYetki === false && (
                              <strong className='typcn typcn-cog-outline'></strong>
                            )} </td>
                            <td className='text-center'>
                              <button
                                className="btn btn-success btn-sm m-1"
                                onClick={() => setDetay(a.id)}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                              {a.slYetki === true &&
                                <button
                                  className="btn btn-danger  btn-sm m-1"
                                  onClick={() => setSil(a)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>}
                            </td>
                          </tr>
                        )) : <tr>
                          <td colSpan={5} className="text-center">Kayıt bulunmuyor.</td></tr>}
                    </tbody>
                  </table>
                </div>
              </>}

            {!data && <LoadSpin></LoadSpin>}

          </div>
        </div>






      </div>
    </div>
  )
}

export default MailTemplate