import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPost, urlD } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Gorsel = ({ detay, setDetay, setFv, setData }) => {

    useEffect(() => {
        console.log(detay)
    }, [detay])

    const [formValue, setFormValue] = useState()
    const [gorsel, setGorsel] = useState();
    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });


    const onSubmit = () => {
        if (detay) {
            if (!gorsel) {
                seterrorS({
                    uyari: { error: true, message: "Görsel seçiniz.", tip: "danger" },
                });

                return;
            }

            setbuttonOk(true);
            var form_data = new FormData();

            form_data.append("file", gorsel);

            userPost("EB_SMedyaUygulamalar/UygGorselGuncelle/" + detay.id, form_data)
                .then((a) => {
                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });
                    console.clear();
                    console.log(a.data)
                    setFv(a.data.item);
                    setData(a.data.liste)
                    setDetay()
                    setbuttonOk(false);



                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }


    return (
        <>
            <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <>
                    <Modal show={detay ? true : false} onHide={() => setDetay()} size={"sm"}>
                        <Modal.Header closeButton>
                            <Modal.Title>Görsel</Modal.Title>
                        </Modal.Header> 
                        <Modal.Body>
                            <form onSubmit={() => onSubmit()}>
                                <div class="text-center">
                                    {detay.gorsel != null ? (
                                        <img
                                            className="me-3 rounded-circle avatarprofil m-4"
                                            src={urlD + "images/apps/" + detay.gorsel}
                                            alt="Uygulama Görseli"
                                        />  
                                    ) : (
                                        <img
                                            className="me-3 rounded-circle avatarprofil m-4"
                                            src={"images/image.png"}
                                            alt="Uygulama Görseli"
                                        />
                                    )}
                                </div>
                                <div class="mb3 row">
                                    <label class="form-label col-sm-12 ">Görsel</label>
                                    <div class="col-sm-12">
                                        <input
                                            type="file"
                                            name="file"
                                            className="form-control"
                                            onChange={e => e.target.files?.length > 0 && setGorsel(e.target.files[0])}
                                        />
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            {!buttonOk && (
                                <>
                                    <Button variant="secondary" onClick={() => setDetay()}>
                                        Kapat
                                    </Button>

                                    <Button variant="success" onClick={() => onSubmit()}>
                                        Güncelle
                                    </Button>
                                </>
                            )}
                            {buttonOk && <LoadSpin />}
                        </Modal.Footer>
                    </Modal>


                </>
            )
            }
        </>
    );

}

export default Gorsel