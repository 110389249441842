import React from 'react'

const ClassSize = [
    { name: 'ExtraSmall', size: '<576px', val: 'xs' },
    { name: 'Small', size: '≥576px', val: 'sm' },
    { name: 'Medium', size: '≥768px', val: 'md' },
    { name: 'Large', size: '≥992px', val: 'lg' },
    { name: 'Extra large', size: '≥1200px', val: 'xl' }

];


const ModulSelect = ({ val,defaultValue, ...props }) => {
    // const size = [
    //     { name: '2', val: '16.6%' },
    //     { name: '3', val: '25%' },
    //     { name: '4', val: '33.3%' },
    //     { name: '5', val: '41.6%' },
    //     { name: '6', val: '50%' }
    //     { name: '6', val: '50%' }
    //     { name: '6', val: '50%' }
    //     { name: '6', val: '50%' }
    //     { name: '6', val: '50%' }

    // ];
    return (<select {...props} defaultValue={defaultValue} >
        <option value={"d-" + val + "-none"}>  Gizli</option>
        <option value={"col-" + val + "-2"}>16.6%</option>
        <option value={"col-" + val + "-3"}>25%</option>
        <option value={"col-" + val + "-4"}>33.3%</option>
        <option value={"col-" + val + "-5"}>41.6%</option>
        <option value={"col-" + val + "-6"}>50%</option>
        <option value={"col-" + val + "-7"}>58.3%</option>
        <option value={"col-" + val + "-8"}>66.6%</option>
        <option value={"col-" + val + "-9"}>75%</option>
        <option value={"col-" + val + "-10"}>83.3%</option>
        <option value={"col-" + val + "-12"} selected>100%</option>
    </select >)
}
export { ClassSize, ModulSelect }

