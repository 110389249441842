import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { urlD, userDell, userGetList } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import { getFormatDatetime } from '../../functionList/funcColl'
import { Button, Modal } from 'react-bootstrap';
import Puan from './components/puan';
import Detay from './components/detay';

import ReactHtmlParser from 'react-html-parser';
import Foto from './components/foto';


const DanismanDetay = () => {
    let { id } = useParams();

    const navigate = useNavigate();
    const [data, setData] = useState();
    const [refresh, setrefresh] = useState();
    const [sil, setSil] = useState();
    const [detay, setDetay] = useState();
    const [foto, setFoto] = useState();

    const [spuan, setPuan] = useState();
    const [buttonOk, setbuttonOk] = useState(false);

    useEffect(() => {
        if (refresh) {
            setData(refresh);
            setrefresh();
        }
    }, [refresh]);


    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    useEffect(() => {
        userGetList("EB_Danismanlar/Detay/" + id)
            .then((a) => {
                console.log(a.data);
                setData(a.data);
            })
            .catch(function (error) {
                Hata();
                seterrorS({
                    uyari: { error: true, message: error.response.data.message, tip: "error" },
                });
                setData();
                return;
            });
    }, []);

    const onRemove = () => {

        if (data) {
            userDell("EB_Danismanlar/Sil/" + id)
                .then((a) => {
                    navigate("/danismanlar");


                })
                .catch(function (error) {

                    seterrorS({
                        uyari: { tip: "error", error: true, message: error.response.data.message },
                    });
                    Hata(error);
                    setbuttonOk(false);

                    return;
                });
        }

    }


    return (

        <>
            <Hatamodal hata={errorS} seterrorS={seterrorS} />

            {data ? (<>
                <Puan detay={spuan} setDetay={setPuan} uid={id} setData={setrefresh} ></Puan>
                <Detay detay={detay} setDetay={setDetay} setData={setrefresh}></Detay>
                <Foto
                    show={foto}
                    setshow={setFoto}
                    setrefresh={setrefresh}
                ></Foto>
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-lg-3">
                            <div className="card">
                                <div className="card-body drDetayPage">
                                    <>
                                        <div className="text-center altpadd">
                                            <Link
                                                to={"#"}
                                                className="btn btn-danger m-1"
                                                onClick={() => setSil(true)}
                                            >
                                                <i className="fa fa-trash"></i> Sil
                                            </Link>

                                            <Button class="btn btn-info m-1" onClick={() => setPuan(data.puan)}>Puan {data.puan}</Button>
                                        </div>
                                    </>

                                    <Link to={"#"} onClick={() => setFoto({ uid: id, baslik: "Profil Foto", img: data.profil })}>
                                        <div className="text-center">
                                            {data.profil != null ? (
                                                <img
                                                    className="me-3 rounded-circle avatarprofil"
                                                    src={urlD + "images/danismanlar/" + data.profil}
                                                    alt={data.adsoyad}
                                                />
                                            ) : (
                                                <img
                                                    className="me-3 rounded-circle avatarprofil"
                                                    src={"images/image.png"}
                                                    alt={data.adsoyad}
                                                />
                                            )}
                                        </div>
                                    </Link>


                                    {!data.durum && (
                                        <div
                                            className="text-center mt-1"
                                            style={{
                                                paddin: "20px auto",
                                                color: "red",
                                                fontSize: "25px",
                                            }}
                                            title="Pasif"
                                        >
                                            <i className="ion ion-md-warning"></i>
                                        </div>
                                    )}
                                    <div className="text-center mt-1">
                                        <h4>
                                            <Link to={"#"}>
                                                {data.adSoyad}
                                            </Link>
                                        </h4>
                                    </div>


                                </div>
                            </div>


                            {data.bayiId &&
                                <div className="card">
                                    <div className="card-body drDetayPage">
                                        <h6 className='text-center'>Bayi</h6>

                                        <Link to={"/bayi-detay-" + data.bayiId} >
                                            <div className="text-center">
                                                {data.logo != null ? (
                                                    <img
                                                        className="me-3 rounded-circle avatarprofil"
                                                        src={urlD + "images/ofisler/" + data.logo}
                                                        alt={data.bayi}
                                                    />
                                                ) : (
                                                    <img
                                                        className="me-3 rounded-circle avatarprofil"
                                                        src={"images/image.png"}
                                                        alt={data.bayi}
                                                    />
                                                )}
                                            </div>
                                        </Link>



                                        <div className="text-center mt-1">
                                            <h6>
                                                <Link to={"#"}>
                                                    {data.bayi}
                                                </Link>
                                            </h6>
                                        </div>

                                    </div>
                                </div>}




                        </div>

                        <div class="col-lg-9">

                            <div className="row">

                                <div className="col-md-12">

                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className='text-center'>Danışman Detay</h4>
                                            <>
                                                <div className="text-end">
                                                    <Link to={"#"} onClick={(e) => setDetay(id)}>
                                                        <i class="mdi mdi-cog"></i>
                                                    </Link>
                                                </div>
                                            </>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <p>
                                                        <strong>Giriş Email: </strong>
                                                        {data.lMail}
                                                    </p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <p>
                                                        <strong>Telefon: </strong>
                                                        {data.tel}
                                                    </p>
                                                </div>

                                                <div className='col-md-3'>
                                                    <p>
                                                        <strong>Kayıt Tarihi: </strong>
                                                        {getFormatDatetime(data.tarih)}
                                                    </p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <p>
                                                        <strong>Site Alt Url:  </strong>
                                                        ../{(data.url)}
                                                    </p>
                                                </div>
                                                <div className='text-center col-md-12'>
                                                    <h6>Not</h6>
                                                    <div className='text-center bg-gray  m-3' >  {data.aciklama} </div>
                                                </div>


                                                <div className='col-md-12 row'>
                                                    <h4 className='Bayi Bilgileri'></h4>
                                                    <div className='col-md-3'>
                                                        <p>
                                                            <strong>Email: </strong>
                                                            {data.mail}
                                                        </p>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <p>
                                                            <strong>Whatsapp: </strong>
                                                            {data.wa}
                                                        </p>
                                                    </div>


                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <div className='col-md-12'>

                                                <div>   <strong>Metin: </strong></div>
                                                {ReactHtmlParser(data.detay)}

                                            </div></div></div>
                                </div>

                            </div>
                        </div>


                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Son Yayınlanan İlanlar</h4>

                                    <div class="table-responsive">
                                        <table class="table mt-4 mb-0 table-centered table-nowrap">

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src="assets/images/users/avatar-2.jpg" alt="user-image" class="avatar-sm rounded-circle me-2" /> Herbert C. Patton
                                                    </td>
                                                    <td><i class="mdi mdi-checkbox-blank-circle text-success"></i>
                                                        Confirm</td>
                                                    <td>
                                                        $14,584
                                                        <p class="m-0 text-muted">Amount</p>
                                                    </td>
                                                    <td>
                                                        5/12/2016
                                                        <p class="m-0 text-muted">Date</p>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <img src="assets/images/users/avatar-3.jpg" alt="user-image" class="avatar-sm rounded-circle me-2" /> Mathias N. Klausen
                                                    </td>
                                                    <td><i class="mdi mdi-checkbox-blank-circle text-warning"></i>
                                                        Waiting payment</td>
                                                    <td>
                                                        $8,541
                                                        <p class="m-0 text-muted">Amount</p>
                                                    </td>
                                                    <td>
                                                        10/11/2016
                                                        <p class="m-0 text-muted">Date</p>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <img src="assets/images/users/avatar-4.jpg" alt="user-image" class="avatar-sm rounded-circle me-2" /> Nikolaj S.
                                                        Henriksen
                                                    </td>
                                                    <td><i class="mdi mdi-checkbox-blank-circle text-success"></i>
                                                        Confirm</td>
                                                    <td>
                                                        $954
                                                        <p class="m-0 text-muted">Amount</p>
                                                    </td>
                                                    <td>
                                                        8/11/2016
                                                        <p class="m-0 text-muted">Date</p>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <img src="assets/images/users/avatar-5.jpg" alt="user-image" class="avatar-sm rounded-circle me-2" /> Lasse C. Overgaard
                                                    </td>
                                                    <td><i class="mdi mdi-checkbox-blank-circle text-danger"></i>
                                                        Payment expired</td>
                                                    <td>
                                                        $44,584
                                                        <p class="m-0 text-muted">Amount</p>
                                                    </td>
                                                    <td>
                                                        7/11/2016
                                                        <p class="m-0 text-muted">Date</p>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <img src="assets/images/users/avatar-6.jpg" alt="user-image" class="avatar-sm rounded-circle me-2" /> Kasper S. Jessen
                                                    </td>
                                                    <td><i class="mdi mdi-checkbox-blank-circle text-success"></i>
                                                        Confirm</td>
                                                    <td>
                                                        $8,844
                                                        <p class="m-0 text-muted">Amount</p>
                                                    </td>
                                                    <td>
                                                        1/11/2016
                                                        <p class="m-0 text-muted">Date</p>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-secondary btn-sm waves-effect">Edit</button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>

            </>) : (
                <LoadSpin></LoadSpin>
            )
            }



            {data && (
                <Modal
                    size="md"
                    show={sil}
                    onHide={() => setSil(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Sil
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body><strong>{data.adSoyad}</strong> bilgisi silinsin mi?</Modal.Body>{" "}
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setSil(false)}>
                                    Vazgeç
                                </Button>
                                <Button variant="danger" onClick={() => onRemove()}>
                                    Sil
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}




        </>
    )
}

export default DanismanDetay