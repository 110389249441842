import React, { useState, useEffect } from 'react'

import Select from 'react-select'
import { userGetList } from '../../../functionList/axiosComp';



const Listeler = ({ type, liste, setListe }) => {

    const tip = (type == "BA1" ? "Ofis " : type == "DA1" ? "Danışman " : "")

    const [icListe, seticListe] = useState(liste)

    const listChance = (i, val) => {
        console.log(val)
        liste[i] = val;
        console.log(liste)
        setListe(liste)

    }




    // useEffect(() => {
    //     setListe(icListe)
    // }, [icListe]) 
    // value={a.value}

    return (
        <div className='borderTG m-3 pt-3'><h5>İçerik Listesi</h5>

            {liste && liste.map((a, i) => <>

                {/*<div className="col-md-1">{i + 1}</div>
                      <div className="col-md-8"> {a.value && a.value + " - " + a.label} </div>
                      <div className="col-md-3">       </div>    */}
                <div className='row m-2' key={i}> <IDetay tip={type} i={i} value={a} key={i} onChange={(e) => { listChance(i, e) }}></IDetay></div>
                {/*  <input type="number" className='form-control' placeholder={tip + ' Id'} onChange={(e) => { listChance(i, e.target.value) }} value={a.value}  ></input> */}
                {/* */}</>


            )
            }

            {/* <div className=' row m-2'>
                <div className="col-md-2">2</div>
                <div className="col-md-8"><input type="text" className='form-control' placeholder={tip + ' Id'}></input></div>
                <div className="col-md-2"><button class="btn btn-danger sil btn-sm  m-1"><i class="fa fa-minus"></i></button></div>
            </div>
            <div className=' row m-2'>
                <div className="col-md-2">3</div>
                <div className="col-md-8"><input type="text" className='form-control' placeholder={tip + ' Id'}></input></div>
                <div className="col-md-2"><button class="btn btn-danger sil btn-sm  m-1"><i class="fa fa-minus"></i></button></div>
            </div>

            <div className=' row m-2'>
                <div className="col-md-2">4</div>
                <div className="col-md-8"><input type="text" className='form-control' placeholder={tip + ' Id'}></input></div>
                <div className="col-md-2"><button class="btn btn-danger sil btn-sm  m-1"><i class="fa fa-minus"></i></button></div>
            </div> */}

        </div >
    )
}

const IDetay = ({ tip, i, value, onChange }) => {
    // onChange={(e) => { listChance(i, e.target.value) }}



    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getOptions = (e) => {

        if (e.length > 2) {
            setIsLoading(true)


            userGetList('EB_Modul/selectOptions/' + tip + '?q=' + e)
                .then((res) => {
                    setIsLoading(true);

                    setOptions(res.data);
                })
                .catch(
                    err => { setIsLoading(false); setOptions([]); }
                );
        } else { setIsLoading(false); setOptions([]); }
    }
    // getOptions()
    return (<>
        <div className='row m-2'>
            <div className="col-md-1">{i + 1}</div>
            <div className="col-md-11">

                <Select className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    defaultValue={value}
                    onChange={e => { onChange(e) }}
                    options={options}
                    onInputChange={e => getOptions(e)}
                ></Select>

            </div>
        </div>
    </>
    )

}

export default Listeler 