import React from 'react'
import { useParams } from 'react-router'

const Sss = () => {
    let { lang } = useParams();
    return (
        <div>sss {lang}</div>
    )
}

export default Sss