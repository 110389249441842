import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {  userPost} from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Detay = ({ detay, setDetay, setData }) => {

  const [formValue, setFormValue] = useState('')
  const [buttonOk, setbuttonOk] = useState(false);


  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });



  useEffect(() => {


    if (detay) {
      setFormValue(detay);
    }
  }, [detay])


  const handleChange = (event) => {

    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }

  const onSubmit = () => {
    if (detay) {
     
      if (formValue.url1.length < 2) {
    
        seterrorS({
          uyari: { error: true, message: "Url giriniz.", tip: "danger" },
        });

        return;
      }

      setbuttonOk(true);
      var form_data = new FormData();




      form_data.append("url1", formValue.url1);

      userPost("EB_Url/guncelle/" + detay.id, form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });


        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />

      {detay && formValue && (
        <Modal
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">



              <div className="form-group ">

                <div className="col-lg-12">
                  <p> <strpng>{formValue.ad}</strpng> Modül Sayfa Url</p>
                </div>
              </div>
 
              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Url :</label>
                <div className="col-lg-12">
                  <input type="text" id="url1" name="url1" className="form-control" onChange={handleChange} value={formValue.url1} />
                </div>
              </div>



            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay