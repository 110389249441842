import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
import { Sehir, Ilce, Semt, Mahalle } from '../../../functionList/citys';

const Yeni = ({ detay, setDetay, setData }) => {

    const [formValue, setFormValue] = useState({
        bayiAdi: '', mah: null, aciklama: '', adres: '', mail: "", Lmail: "", puan: "0", site: "", telefon: "", wa: "",

    })


    const [SehirList, setSehirList] = useState([]);
    const [IlceList, setIlceList] = useState([]);
    const [SemtList, setSemtList] = useState([]);
    const [MahalleList, setMahalleList] = useState([]);


    useEffect(() => { 
        Sehir().then(a => setSehirList(a.data));
    }, [])


    function ilceGetir(id) {

        setFormValue({
            ...formValue,
            ['mah']: null
        });

        if (id !== 0) {

            Ilce(id).then(a => { console.log(a.data); setIlceList(a.data) });

            setSemtList([])
            setMahalleList([])
        } else {
            setIlceList()
            setSemtList([])
            setMahalleList([])


        }

    }

    function semtGetir(id) {

        setFormValue({
            ...formValue,
            ['mah']: null
        });

        if (id !== 0) {
            console.log(id);
            Semt(id).then(a => { console.log(a.data); setSemtList(a.data); });

            setMahalleList([])
        } else {

            setSemtList([])
            setMahalleList([])
        }


    }

    function mahGetir(id) {
        setFormValue({
            ...formValue,
            ['mah']: null
        });
        if (id !== 0) {
            Mahalle(id).then(a => setMahalleList(a.data));
        } else {
            setMahalleList([]);
        }

    }



    const [buttonOk, setbuttonOk] = useState(false);
    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });



    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }

    const onEkle = () => {

        if (detay) {
            console.log(formValue.mah)

            if (!formValue.mah || formValue.mah == null) {
                seterrorS({
                    uyari: { error: true, message: "Şehir - İlçe - Semt- Mahalle boş geçilemez.", tip: "danger" },
                });
                return;
            } else if (formValue.bayiAdi.length < 2) {
                seterrorS({
                    uyari: { error: true, message: "Bayi adı giriniz.", tip: "danger" },
                });

                return false;
            }
            else if (!formValue.Lmail) {
                seterrorS({
                    uyari: { error: true, message: "Geçerli 'Giriş Mail'   adresi giriniz.", tip: "danger" },
                });
                return;
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValue.Lmail)) {
                seterrorS({
                    uyari: { error: true, message: "Geçerli 'Giriş Mail'   adresi giriniz.", tip: "danger" },
                });
                return;
            }
            else {

                setbuttonOk(true);
                var form_data = new FormData();

                for (var key in formValue) {
                    form_data.append(key, formValue[key]);
                }

                userPut("EB_Bayiler/Yeni", form_data)
                    .then((a) => {
                        setData(a.data);
                        setbuttonOk(false);

                        seterrorS({
                            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                        });
                        setDetay();
                        setFormValue({
                            bayiAdi: '', mah: null, aciklama: '', adres: '', mail: "", Lmail: "", puan: "0", site: "", telefon: "", wa: "",

                        })

                    })
                    .catch(function (error) {
                        setbuttonOk(false);
                        seterrorS({
                            uyari: { tip: "danger", error: true, message: error.response.data.message },
                        });
                        Hata(error);


                        return;
                    });



            }


        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row">
                          

                            <div className="form-group col-md-3">
                                <label className="col-lg-3 control-label">Şehir :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={(e) => { ilceGetir(e.target.value) }} name="sehir" id="sehir">
                                        <option value={0}>Seçiniz</option>
                                        {SehirList && SehirList.map((a) =>
                                            <option value={a.id}>{a.yer}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="col-lg-3 control-label">İlçe :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={(e) => { semtGetir(e.target.value) }} name="ilce" id="ilce">
                                        <option value={0}>Seçiniz</option>
                                        {IlceList && IlceList.map((a) =>
                                            <option value={a.id}>{a.yer}</option>)}

                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="col-lg-3 control-label">Semt :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={(e) => { mahGetir(e.target.value) }} name="Semt" id="Semt">
                                        <option value={0}>Seçiniz</option>
                                        {SemtList && SemtList.map((a) =>
                                            <option value={a.id}>{a.yer}</option>)}

                                    </select>
                                </div>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="col-lg-3 control-label">Mahalle :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.mah} onChange={handleChange} name="mah" id="mah">
                                        <option value={0} selected={formValue.mah === null}>Seçiniz</option>
                                        {MahalleList && MahalleList.map((a) =>
                                            <option value={a.id} selected={formValue.mah === a.id}>{a.yer}</option>)}

                                    </select>
                                </div>
                            </div>




                            <div className="form-group mb-4  col-md-9">
                                <label className="col-lg-3 control-label">Bayi Adı :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="bayiAdi" name="bayiAdi" className="form-control" onChange={handleChange} value={formValue.bayiAdi} />
                                </div>
                            </div>
                            <div className="form-group mb-4  col-md-3">
                                <label className="col-lg-3 control-label">Puan :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="puan" name="puan" className="form-control" onChange={handleChange} value={formValue.puan} />
                                </div>
                            </div>

                            <div className="form-group mb-4 ">
                                <label className="col-lg-3 control-label">Giriş Mail Adresi :</label>
                                <div className="col-lg-12">
                                    <input type="email" id="Lmail" name="Lmail" className="form-control" onChange={handleChange} value={formValue.Lmail} />
                                </div>
                            </div>



                            <div className="form-group mb-4 col-md-6">
                                <label className="col-lg-3 control-label">Mail :</label>
                                <div className="col-lg-12">
                                    <input type="email" id="mail" name="mail" className="form-control" onChange={handleChange} value={formValue.mail} />
                                </div>
                            </div>


                            <div className="form-group mb-4 col-md-6">
                                <label className="col-lg-3 control-label">Site :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="site" name="site" className="form-control" onChange={handleChange} value={formValue.site} />
                                </div>
                            </div>

                            <div className="form-group mb-4 col-md-6">
                                <label className="col-lg-3 control-label">Telefon :</label>
                                <div className="col-lg-12">
                                    <input type="phone" id="telefon" name="telefon" className="form-control" onChange={handleChange} value={formValue.telefon} />
                                </div>
                            </div>

                            <div className="form-group mb-4 col-md-6">
                                <label className="col-lg-3 control-label">Whatsapp :</label>
                                <div className="col-lg-12">
                                    <input type="phone" id="wa" name="wa" className="form-control" onChange={handleChange} value={formValue.wa} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Adres :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="adres" name="adres" className="form-control" onChange={handleChange} value={formValue.adres} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Açıklama :</label>
                                <div className="col-lg-12">
                                    <textarea type="text" id="aciklama" name="aciklama" className="form-control" onChange={handleChange} value={formValue.aciklama}  ></textarea>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant='success' onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )
            }

        </>
    )
}

export default Yeni   