import React from 'react'

const Footer = () => {
  
  return (
    <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
      
          <section class="copyright-45-sec-326">
            <a href="https://www.ixxi.com.tr" target="_blank">
                 © İxxi.com.tr aracılığı hazırlanmıştır. 
            </a>
          </section>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer