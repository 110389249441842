import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Yeni = ({ detay, setDetay, setData }) => {

    const [formValue, setFormValue] = useState({
        ad: '', link: '', sira: 5

    })

    const [gorsel, setGorsel] = useState();

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });






    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }

    const onEkle = () => {
        if (detay) {


            if (formValue.ad.length < 2) {
                seterrorS({
                    uyari: { error: true, message: "Platform adı giriniz.", tip: "danger" },
                });

                return;
            }
            if (!gorsel ) {
                seterrorS({
                    uyari: { error: true, message: "Platform görseli yükleyiniz.", tip: "danger" },
                });

                return;
            }


            setbuttonOk(true);
            var form_data = new FormData();

            form_data.append("file", gorsel);

            for (var key in formValue) {
                form_data.append(key, formValue[key]);
            }

            userPut("EB_SMedyaUygulamalar/UygEkle", form_data)
                .then((a) => {
                    setData(a.data);
                    setbuttonOk(false);

                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });
                    setDetay();
                    setFormValue({
                        ad: '', link: '', sira: 5
                
                    })

                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                        
                            <div class="mb3 row">
                                <label class="form-label col-sm-2 ">Foto</label>
                                <div class="col-sm-10">
                                    <input
                                        type="file"
                                        name="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={e=>e.target.files?.length>0&&setGorsel(e.target.files[0])}
                                    />
                                </div>
                            </div>



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Platfom :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="ad" name="ad" className="form-control" onChange={handleChange} value={formValue.ad} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Sıra :</label>
                                <div className="col-lg-12">
                                    <input type="number" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Link :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="link" name="link" className="form-control" onChange={handleChange} value={formValue.link} />
                                </div>
                            </div>






                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni