import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { getList, post } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import SunEditor from 'suneditor-react';
const Detay = ({ detay, setDetay, setData }) => {

  const [formValue, setFormValue] = useState()
  const [buttonOk, setbuttonOk] = useState(false);
  const [katSelect, setKatSelect] = useState();

  const [editor, seteditor] = useState('');

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    getList("EB_Kategoriler/katSelect")
      .then((a) => {

        setKatSelect(a.data);

      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });

        return;
      });
  }, []);

  useEffect(() => {

    if (detay) {
      getList("EB_Kategoriler/Detay/" + detay)
        .then((a) => {
 
          setFormValue(a.data);

          if (a.data.seoMetin) { seteditor(a.data.seoMetin); }
          setbuttonOk(false)

        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])





  const handleChange = (event) => {

    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }

  const onSubmit = () => {
    if (detay) {
      if (formValue.katAd.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Kategori adı giriniz.", tip: "danger" },
        });

        return;
      }

      setbuttonOk(true);
      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      post("EB_Kategoriler/Guncelle/" + detay, form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });


        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">


              <Tabs
                defaultActiveKey="genel"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="genel" title="Genel">
                  <div className="row">


                    <div className="form-group">
                      <label className="col-lg-3 control-label">Durum?:</label>
                      <div className="col-lg-12">
                        <select className="form-control" defaultValue={formValue.durum} onChange={handleChange} name="durum" id="durum">
                          <option value={true}>Evet</option>
                          <option value={false}>Hayır</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-lg-3 control-label">Üst Kategori:</label>
                      <div className="col-lg-12">
                        <select className="form-control" onChange={handleChange} name="ustKatid" id="ustKatid">

                          {katSelect && katSelect.map((a) =>
                            <option value={a.katId.toString()} selected={formValue.ustKatid.toString() === a.katId.toString()}> {a.katAd}</option>)}

                        </select>
                      </div>
                    </div>


                    <div className="form-group mb-4">
                      <label className="col-lg-3 control-label">Kategori Adı :</label>
                      <div className="col-lg-12">
                        <input type="text" id="katAd" name="katAd" className="form-control" onChange={handleChange} value={formValue.katAd} />
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label className="col-lg-3 control-label">Sıra :</label>
                      <div className="col-lg-12">
                        <input type="number" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                      </div>
                    </div>


                  </div>

                </Tab>
                <Tab eventKey="Detay" title="Detay">
                  <div className="form-group mb-4">
                    <label className="col-lg-3 control-label">Seo Başlık :</label>
                    <div className="col-lg-12">
                      <input type="text" id="seoTitle" name="seoTitle" className="form-control" onChange={handleChange} value={formValue.seoTitle} />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-lg-12 control-label">Seo Metin:</label>
                    <div className="col-lg-12">

                      <SunEditor defaultValue={editor} onChange={seteditor} name="metin" setOptions={{
                        buttonList: [
                          // default
                          ['undo', 'redo'],
                          [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                          ['fontColor', 'hiliteColor', 'textStyle'],
                          ['removeFormat'],
                          ['outdent', 'indent'],
                          ['align', 'horizontalRule', 'list', 'lineHeight']
                        ]
                      }} />


                    </div>
                  </div>


                </Tab>

              </Tabs>




            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay