import React, { useState, useEffect } from 'react'
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import Hata from '../../functionList/hata';
import { Button, Modal } from 'react-bootstrap'
import { userGetList, userPost } from '../../functionList/axiosComp';

const DilBolge = () => {


  const [data, setData] = useState();
  const [detay, setDetay] = useState();

  const [buttonOk, setbuttonOk] = useState(false);
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {
    userGetList("EB_dil/Index")
      .then((a) => {
        console.log(a.data);
        setData(a.data);
      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
        setData();
        return;
      });
  }, []);



  const handleChange = (event) => {
    setDetay({
      ...detay,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });
  }


  const onSubmit = () => {
    if (detay) {
      if (detay.dil.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Platform adı giriniz.", tip: "danger" },
        });

        return;
      }

      setbuttonOk(true);
      var form_data = new FormData();

      for (var key in detay) {
        form_data.append(key, detay[key]);
      }

      userPost("EB_dil/dilAyarGuncelle/" + detay.lid, form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });
        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);
          return;
        });
    }
  }



  return (
    <div className="container-fluid">

      <div className='card'> <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>
        <div className="card-body">
          <h4>Diller </h4>
          <div className="row">

            {data &&
              <>
                <div className="table-responsive">
                  <table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Dil</th>
                        <th>Code</th>
                        <th>Yazı Yönü</th>
                        <th>Durum</th>
                        <th className='text-center'>İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((a, index) => (
                        <tr className={a.vars && 'varsl'}>
                          <td>{index + 1}</td>
                          <td>{a.dil}</td>
                          <td>{a.browserLang}</td>
                          <td>{(a.yaziYon === true ? <span className='badge badge-soft-primary'>Sağ</span> : <span className='badge badge-soft-info'>Sol</span>)}</td>
                          <td className='text-center'>{a.durum === true ? (
                            <i className="fa fa-check check"></i>
                          ) : (
                            <i className="fa fa-times times"></i>
                          )}</td>
                          <td className='text-center'>

                            <button
                              className="btn btn-success btn-sm m-1"
                              onClick={() => setDetay(a)}
                            >
                              <i className="fa fa-edit"></i>
                            </button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>}

            {!data && <LoadSpin></LoadSpin>}

          </div>
        </div>




        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {detay && <div className="row">



              {!detay.vars &&
                <div className="form-group  mb-4">
                  <label className="col-lg-3 control-label">Durum?:</label>
                  <div className="col-lg-12">
                    <select className="form-control" defaultValue={detay.durum} onChange={handleChange} name="durum" id="durum">
                      <option value={true}>Evet</option>
                      <option value={false}>Hayır</option>
                    </select>
                  </div>
                </div>}

              <div className="form-group  mb-4">
                <label className="col-lg-3 control-label">Yazı Yönü?:</label>
                <div className="col-lg-12">
                  <select className="form-control" defaultValue={detay.yaziYon} onChange={handleChange} name="yaziYon" id="yaziYon">
                    <option value={true}>Sağ</option>
                    <option value={false}>Sol</option>
                  </select>
                </div>
              </div>


              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Dil :</label>
                <div className="col-lg-12">
                  <input type="text" id="dil" name="dil" className="form-control" onChange={handleChange} value={detay.dil} />
                </div>
              </div>



            </div>}
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>




      </div>
    </div>
  )
}

export default DilBolge