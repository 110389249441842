import React, { useState, useEffect } from 'react'
import { userGetList } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import { getFormatDatetime } from '../../functionList/funcColl'

import Yeni from './components/yeni';
import { Link } from 'react-router-dom'; 

const Bayiler = () => {

  const [data, setData] = useState();

  const [yeni, setYeni] = useState();
 
  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });
 
  useEffect(() => {
    userGetList("EB_Bayiler/Index")
      .then((a) => {
        console.log(a.data);
        setData(a.data);
      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
        setData();
        return;
      });
  }, []);

 

  return (
    <div class="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

      <Yeni detay={yeni} setDetay={setYeni} setData={setData}></Yeni>
 


      <div className='card'>
        <div className="card-body">
          <h4>Bayi Listesi</h4>
          <p>Bu bölümden Bayiler ,bayi ilanları ve bayi danışmanlarını   yönetebilirsiniz.</p>

          <div className="text-end">
            <button onClick={() => setYeni(true)} className="btn btn-info">
              <i className="fas fa-plus"></i> Yeni
            </button>
          </div>


          <div className="row">

            {data &&
              <>
                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th width="90">#</th>
                        <th width="90">Paun</th>
                        <th>Bayi</th>
                        <th>Şehir</th>
                        <th>Tel</th>
                        <th>Danışman</th>
                        <th>İlan</th>
                        <th>Website</th>
                        <th width="90">Tarih</th>
                        <th className='text-center' width="120">Durum</th>
                        <th className='text-center' width="120">İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ?
                        data.map((a, index) => (
                          <tr key={a.id}  >
                            <td>{a.id}</td>
                            <td>{a.puan}</td>
                            <td>{a.bayiAdi}</td>
                            <td>{a.adres}</td>
                            <td>{a.telefon}</td>
                            <td>{a.danismanC}</td>
                            <td>{a.urunC}</td>
                            <td>{a.site}</td>

                            <td>{getFormatDatetime(a.tarih)}</td>


                            <td className='text-center'>{a.durum ? (
                              <i className="fa fa-check check"></i>
                            ) : (
                              <i className="fa fa-times times"></i>
                            )}</td>
                            <td className='text-center'>  
                              <Link to={"/bayi-detay-"+a.id}
                                className="btn btn-success btn-sm m-1"
                              // onClick={() => setDetay(a.id)}
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
 
                            </td>
                          </tr>
                        )) : <tr>
                          <td colSpan={11} className="text-center">Kayıt bulunmuyor.</td></tr>}
                    </tbody>
                  </table>
                </div>
              </>}

            {!data && <LoadSpin></LoadSpin>}

          </div>
        </div>






      </div>
    </div>
  )

}

export default Bayiler