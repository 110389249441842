import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {  userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import SunEditor from 'suneditor-react';

const Yeni = ({ detay, setDetay, setData }) => {


    const [formValue, setFormValue] = useState({
        title: '',
        metin: '',
        footer: false,
        url: ''
    })


    const [editor, seteditor] = useState();



    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });

    useEffect(() => {
        console.log(formValue); console.log(editor);
    }, [editor, formValue])





    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });



    }

    const onEkle = () => {
        if (detay) {
            if (formValue.title.length < 3) {
                seterrorS({
                    uyari: { error: true, message: "Başlık giriniz.", tip: "danger" },
                });

                return;
            }

            formValue.metin = editor;

            setbuttonOk(true);
            var form_data = new FormData();

            for (var key in formValue) {
                form_data.append(key, formValue[key]);
            }

            userPut("EB_Pages/Yeni", form_data)
                .then((a) => {


                    setbuttonOk(false);
                    setData(a.data);

                    setFormValue({
                        title: '',
                        metin: '',
                        footer: false,
                        url: ''
                    });
                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });
                    setDetay();
                    seteditor()

                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Footera Sabitle:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.footer} onChange={handleChange} name="footer" id="footer">
                                        <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label"> Başlık   :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="title" name="title" className="form-control" onChange={handleChange} value={formValue.title} />
                                </div>
                            </div>
                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label"> Url (istege Baglı*) :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="url" name="url" className="form-control" onChange={handleChange} value={formValue.url} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-3 control-label"> Metin:</label>
                                <div className="col-lg-12">

                                    <SunEditor defaultValue={editor} onChange={seteditor} name="metin" setOptions={{
                                        buttonList: [
                                            // default
                                            ['undo', 'redo'],
                                            [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['fontColor', 'hiliteColor', 'textStyle'],
                                            ['removeFormat'],
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                                            ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save', 'template'],
                                            ['-right', 'image', 'video', 'audio', 'link'],

                                        ]
                                    }} />


                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni