import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userGetList, userPost, urlD } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
import Gorsel from './gorsel';


const Detay = ({ detay, setDetay, setData }) => {


  const [sdetay, setSdetay] = useState();

  const [formValue, setFormValue] = useState()
  const [buttonOk, setbuttonOk] = useState(false);

  


  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });


  useEffect(() => {

    if (detay) {
      userGetList("EB_SMedyaUygulamalar/UygDetay/" + detay)
        .then((a) => {
          console.clear();
          console.log(a.data);
          setFormValue(a.data);

          setbuttonOk(false)


        })
        .catch(function (error) {
          Hata(error)
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false)

          return;
        });
    }

  }, [detay])





  const handleChange = (event) => {

    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
    });

  }

  const onSubmit = () => {
    if (detay) {
      if (formValue.ad.length < 2) {
        seterrorS({
          uyari: { error: true, message: "Platform adı giriniz.", tip: "danger" },
        });

        return;
      }

      setbuttonOk(true);
      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      userPost("EB_SMedyaUygulamalar/UygGuncelle/" + detay, form_data)
        .then((a) => {
          setData(a.data);
          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
          });


        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: { tip: "danger", error: true, message: error.response.data.message },
          });
          Hata(error);


          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <Gorsel detay={sdetay} setDetay={setSdetay} setFv={setFormValue} setData={setData}></Gorsel>
      {detay && formValue && (
        <Modal
          size="xl"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Detay
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
 
              <div class="text-center">
                {formValue.gorsel != null ? (
                  <img onClick={() => setSdetay(formValue)}
                    className="me-3 rounded-circle avatarprofil"
                    src={urlD + "images/apps/" + formValue.gorsel}
                    alt="Uygulama Görseli"
                  />
                ) : (
                  <img onClick={() => setSdetay(formValue)}
                    className="me-3 rounded-circle avatarprofil"
                    src={"images/image.png"}
                    alt="Uygulama Görseli"
                  />
                )}
              </div>
              {/* <div class="mb3 row">
                <label class="form-label col-sm-2 ">Foto</label>
                <div class="col-sm-10">
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div> */}



              <div className="form-group  mb-4">
                <label className="col-lg-3 control-label">Durum?:</label>
                <div className="col-lg-12">
                  <select className="form-control" defaultValue={formValue.durum} onChange={handleChange} name="durum" id="durum">
                    <option value={true}>Evet</option>
                    <option value={false}>Hayır</option>
                  </select>
                </div>
              </div>


              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Platfom :</label>
                <div className="col-lg-12">
                  <input type="text" id="ad" name="ad" className="form-control" onChange={handleChange} value={formValue.ad} />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Sıra :</label>
                <div className="col-lg-12">
                  <input type="number" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                </div>
              </div>
              <div className="form-group mb-4">
                <label className="col-lg-3 control-label">Link :</label>
                <div className="col-lg-12">
                  <input type="text" id="link" name="link" className="form-control" onChange={handleChange} value={formValue.link} />
                </div>
              </div>


            </div>
          </Modal.Body>
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="success" onClick={() => onSubmit()}>
                  Onayla
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}

export default Detay