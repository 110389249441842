import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { userGetList, userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';


const StyleCss = ({ detay, setDetay }) => {

    const [formValue, setFormValue] = useState();

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });



    useEffect(() => {

        if (detay) {

            userGetList("EB_Modul/styleCss")
                .then((a) => {

                    setFormValue(a.data);

                    setbuttonOk(false)

                })
                .catch(function (error) {
                    Hata(error)
                    seterrorS({
                        uyari: { tip: "error", error: true, message: error.response.data.message },
                    });
                    setbuttonOk(false)

                    return;
                });
        }

    }, [detay])



    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }


    const onSubmit = () => {
        if (detay) {

            setbuttonOk(true);

            var form_data = new FormData();
            form_data.append('style',formValue)
 

            userPost("EB_Modul/styleCssGuncelle", form_data)
                .then((a) => { 
                    setbuttonOk(false); 
                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });
 
                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }



    return (

        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && formValue && (
                <>

             
                    <Modal
                        size="xl"
                        show={detay ? true : false}
                        onHide={() => setDetay()}
                        aria-labelledby="example-modal-sizes-title-sm"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Css
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {formValue &&
                                <div>
                                    <div className="form-group mb-4">

                                        <div className="col-lg-12">
                                            <textarea style={{minHeight:"750px"}} type="text" id="stylecss" name="stylecss" className="form-control" onChange={(e) => setFormValue(e.target.value)} value={formValue} />
                                        </div>
                                    </div>
                                </div>

                            }


                        </Modal.Body>
                        <Modal.Footer>
                            {!buttonOk && (
                                <>
                                    <Button variant="secondary" onClick={() => setDetay()}>
                                        Kapat
                                    </Button>

                                    <Button variant="success" onClick={() => onSubmit()}>
                                        Onayla
                                    </Button>
                                </>
                            )}
                            {buttonOk && <LoadSpin />}
                        </Modal.Footer>
                    </Modal></>

            )}

        </>


    )
}

export default StyleCss