import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

import SunEditor from 'suneditor-react';

const Yeni = ({ detay, setDetay, setData }) => {


    const [formValue, setFormValue] = useState({
        durum: true,
        id: 0,
        slYetki: true,
        templateAd: "",
        templateIcerik: ""

    })




    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });






    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }

    const onEkle = () => {
        if (detay) {
            if (formValue.templateAd.length < 3) {
                seterrorS({
                    uyari: { error: true, message: "Template adı giriniz.", tip: "danger" },
                });

                return;
            }

            setbuttonOk(true);
            var form_data = new FormData();

            for (var key in formValue) {
                form_data.append(key, formValue[key]);
            }

            userPut("EB_MailTemplate/Yeni", form_data)
                .then((a) => {
                    setData(a.data);
                    setbuttonOk(false);

                    seterrorS({
                        uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                    });
                    setDetay();

                    setFormValue({
                        durum: true,
                        id: 0,
                        slYetki: true,
                        templateAd: "",
                        templateIcerik: ""

                    });

                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Template Ad :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="templateAd" name="templateAd" className="form-control" onChange={handleChange} value={formValue.templateAd} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-3 control-label">Template Metni:</label>
                                <div className="col-lg-12">

                                    <SunEditor onChange={e => {
                                        setFormValue({
                                            ...formValue,
                                            ['templateIcerik']: e
                                        })
                                    }} defaultValue={formValue.templateIcerik} name="templateIcerik" setOptions={{
                                        buttonList: [
                                            // default
                                            ['undo', 'redo'],
                                            [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['fontColor', 'hiliteColor', 'textStyle'],
                                            ['removeFormat'],
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                                            ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save', 'template'],
                                            ['-right', 'image', 'video', 'audio', 'link'],

                                        ]
                                    }} />


                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni