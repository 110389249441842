import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPut } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';
import { ModulSelect, ClassSize } from '../../../functionList/moduleSize';

const Yeni = ({ detay, setDetay, setData, modul }) => {

    const [formValue, setFormValue] = useState({
        title: '', titleG: false, desc: '', descG: false, col: 1, sira: 1, css: '', bitisTarihi: ''

    })

    const [buttonOk, setbuttonOk] = useState(false);

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });






    const handleChange = (event) => {

        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value
        });

    }

    const onEkle = () => {





        if (detay) {


            if (formValue.title.length < 2) {
                seterrorS({
                    uyari: { error: true, message: "Grup başlığı giriniz.", tip: "danger" },
                });

                return;
            }


            setbuttonOk(true);
            var form_data = new FormData();

            for (var key in formValue) {
                form_data.append(key, formValue[key]);
            }
            form_data.append('modul', modul);


            const selectList = document.getElementsByClassName("msizecss");

            const coll = ([...Array(parseInt(formValue.col))])

            coll.forEach((a, i) => {
                const selFlList = (Array.from(selectList).filter(a => a.name === "col" + (i + 1)));

                let alValue = '';
                selFlList.forEach((a) => {

                    alValue = alValue + ' ' + a.value;
                })
                form_data.append("col-" + (i + 1), alValue);
            });


            userPut("EB_Modul/ModulYeni", form_data)
                .then((a) => {
                    console.log((a.data))
                    setData(a.data.liste);
                    setbuttonOk(false);

                    setDetay();
                    setFormValue({
                        title: '', col: 0, sira: 1, css: '', bitisTarihi: ''
                    })
                    seterrorS({
                        uyari: { tip: "success", error: true, message: 'Modül Eklendi' },
                    });



                })
                .catch(function (error) {
                    setbuttonOk(false);
                    seterrorS({
                        uyari: { tip: "danger", error: true, message: error.response.data.message },
                    });
                    Hata(error);


                    return;
                });
        }
    }

    return (
        <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {detay && (
                <Modal
                    size="xl"
                    show={detay ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Yeni
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">

                            <div className="mb-4 col-md-8">
                                <label className="col-lg-12 control-label">Başlık :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="title" name="title" className="form-control" onChange={handleChange} value={formValue.title} />
                                </div>
                            </div>


                            <div className="mb-4 col-md-4">
                                <label className="col-lg-12 control-label"> Başlık Göster :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.titleG} onChange={handleChange} name="titleG" id="titleG">
                                        <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mb-4 col-md-8">
                                <label className="col-lg-12 control-label">Açıklama :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="desc" name="desc" className="form-control" onChange={handleChange} value={formValue.desc} />
                                </div>
                            </div>


                            <div className="mb-4 col-md-4">
                                <label className="col-lg-12 control-label"> Açıklama Göster :</label>
                                <div className="col-lg-12">
                                    <select className="form-control" defaultValue={formValue.descG} onChange={handleChange} name="descG" id="descG">
                                        <option value={true}>Evet</option>
                                        <option value={false}>Hayır</option>
                                    </select>
                                </div>
                            </div>



                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Sıra :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="sira" name="sira" className="form-control" onChange={handleChange} value={formValue.sira} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Css :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="css" name="css" className="form-control" onChange={handleChange} value={formValue.css} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Bitiş Tarihi :</label>
                                <div className="col-lg-12">
                                    <input type="date" id="bitisTarihi" name="bitisTarihi" className="form-control" onChange={handleChange} value={formValue.bitisTarihi} />
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Sütun Sayısı :</label>
                                <div className="col-lg-12">
                                    <select type="date" id="col" name="col" className="form-control" onChange={handleChange} value={formValue.col}>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            {ClassSize.map((a) => <th>{a.name}<br />
                                                <span className='font-weight-light'>{a.size}</span></th>)}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {formValue && [...Array(parseInt(formValue.col))].map((x, i) => <tr> <th>{i + 1}</th> {ClassSize.map((a) => <th>   <ModulSelect name={"col" + (i + 1)} val={a.val} className="form-control msizecss"></ModulSelect></th>)}
                                        </tr>)}

                                    </tbody>
                                </table>
                            </div>






                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onEkle()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
}

export default Yeni