import React from 'react' 
import { useDispatch } from "react-redux";
import { userLogout } from '../redux/redux'

const Hata = (event) => {
    var dispatch = useDispatch();
    const OnCikis = () => {
        console.log("burda");
        dispatch(userLogout())

    }
    if (event.response.status === '401') {
        console.log("11bb")
        OnCikis()
    }
}

export default Hata   