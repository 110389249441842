import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { userPost } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Puan = ({ detay, setDetay, uid, setData }) => {
    const [buttonOk, setbuttonOk] = useState(false);
    const [formValue, setFormValue] = useState(0)

    const [errorS, seterrorS] = useState({
        uyari: { error: false, message: "", tip: null },
    });


    useEffect(() => { 
        if (detay) { 
            setFormValue(detay)
        }
    }, [detay])


    const onSubmit = () => {
        console.log(formValue)
       
        if (formValue.length === 0) {
            seterrorS({
                uyari: { error: true, message: "Paun giriniz", tip: "danger" },
            });

            return;
        }  
        setbuttonOk(true);
        var form_data = new FormData();


        form_data.append("puan", formValue);


        userPost("EB_Bayiler/PuanGuncelle/" + uid, form_data)
            .then((a) => {
                setData(a.data);
                setbuttonOk(false);

                seterrorS({
                    uyari: { error: true, message: "İşlem başarılı.", tip: "success" },
                });


            })
            .catch(function (error) {
                setbuttonOk(false);
                seterrorS({
                    uyari: { tip: "danger", error: true, message: error.response.data.message },
                });
                Hata(error);


                return;
            });

    }


    return (
        <>
            <Hatamodal hata={errorS} seterrorS={seterrorS} />
            {(detay || detay === 0) && (

                <>      <Modal
                    size="xl"
                    show={detay != null ? true : false}
                    onHide={() => setDetay()}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Detay
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">


                            <div className="form-group mb-4">
                                <label className="col-lg-3 control-label">Puan :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="puan" name="puan" className="form-control" onChange={(e) => setFormValue(parseInt(e.target.value.length===0?0:e.target.value))} value={formValue} />
                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {!buttonOk && (
                            <>
                                <Button variant="secondary" onClick={() => setDetay()}>
                                    Vazgeç
                                </Button>
                                <Button variant="success" onClick={() => onSubmit()}>
                                    Onayla
                                </Button>
                            </>
                        )}
                        {buttonOk && <LoadSpin />}
                    </Modal.Footer>
                </Modal></>

            )}

        </>
    )
}

export default Puan