import React, { useState, useEffect } from 'react'
import { urlD, userGetList } from '../../functionList/axiosComp';
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import { getFormatDatetime } from '../../functionList/funcColl'

import Yeni from './components/yeni';
import { Link } from 'react-router-dom'; 


const Danismanlar = () => {

  const [data, setData] = useState();

  const [yeni, setYeni] = useState();

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  useEffect(() => {
    userGetList("EB_Danismanlar/Index")
      .then((a) => {
        console.log(a.data);
        setData(a.data);
      })
      .catch(function (error) {
        Hata();
        seterrorS({
          uyari: { error: true, message: error.response.data.message, tip: "error" },
        });
        setData();
        return;
      });
  }, []);



  return (
    <div class="container-fluid">
      <Hatamodal hata={errorS} seterrorS={seterrorS} ></Hatamodal>

      <Yeni detay={yeni} setDetay={setYeni} setData={setData}></Yeni>



      <div className='card'>
        <div className="card-body">
          <h4>Danışman Listesi</h4>
          <p>Bu bölümden danışaman  bilgilerini  yönetebilirsiniz.</p>

          <div className="text-end">
            <button onClick={() => setYeni(true)} className="btn btn-info">
              <i className="fas fa-plus"></i> Yeni
            </button>
          </div>


          <div className="row">

            {data &&
              <>
                <div class="table-responsive">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th width="90">#</th>
                        <th width="90"></th>
                        <th width="90">Paun</th>
                        <th>Adsoyad</th>
                        <th>Bayi</th>
                        <th>Tel</th>
                        <th>İlan</th>
                        <th width="90">Tarih</th>
                        <th className='text-center' width="120">Durum</th>
                        <th className='text-center' width="120">İşlem</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ?
                        data.map((a, index) => (
                          <tr key={a.id}  >
                            <td>{a.id}</td>
                            <td>{a.profil ? (
                              <img
                                className="me-3   "
                                src={urlD + "images/danismanlar/" + a.profil}
                                alt={a.adSoyad}
                                style={{ maxHeight: "50px" ,maxWidth:"50px"}}
                              />
                            ) : (
                              <img
                                className="me-3 "
                                src={"images/image.png"}
                                alt={a.adSoyad}
                                style={{ maxHeight: "50px" }}
                              />
                            )}</td>
                            <td>{a.puan}</td>
                            <td>{a.adSoyad}</td>
                            <td>{a.bayi}</td>
                            <td>{a.tel}</td> 
                            <td>{a.urunC}</td>  
                            <td>{getFormatDatetime(a.tarih)}</td>
 
                            <td className='text-center'>{a.durum ? (
                              <i className="fa fa-check check"></i>
                            ) : (
                              <i className="fa fa-times times"></i>
                            )}</td>
                            <td className='text-center'>
                              <Link to={"/danisman-detay-" + a.id}
                                className="btn btn-success btn-sm m-1"
                              // onClick={() => setDetay(a.id)}
                              >
                                <i className="fa fa-edit"></i>
                              </Link>

                            </td>
                          </tr>
                        )) : <tr>
                          <td colSpan={11} className="text-center">Kayıt bulunmuyor.</td></tr>}
                    </tbody>
                  </table>
                </div>
              </>}

            {!data && <LoadSpin></LoadSpin>}

          </div>
        </div>






      </div>
    </div>
  )

}

 

export default Danismanlar