import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { dell } from '../../../functionList/axiosComp';
import Hata from '../../../functionList/hata';
import Hatamodal from '../../../functionList/hatamodal';
import LoadSpin from '../../../functionList/loadSpin';

const Sil = ({ detay, setDetay, setData }) => {


  useEffect(() => {
    setbuttonOk(false);
  }, [detay])

  const [buttonOk, setbuttonOk] = useState(false);

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: null },
  });

  const onRemove = () => {
    if (detay && detay.id) {
      setbuttonOk(true);
      var form_data = new FormData();

      dell("EB_Kategoriler/Sil/" + detay.id)
        .then((a) => {

          setbuttonOk(false);

          seterrorS({
            uyari: { error: true, message: "İşlem Başarılı.", tip: "success" },
          });
          setData(a.data);
          setDetay();
        })
        .catch(function (error) {
          seterrorS({
            uyari: { tip: "error", error: true, message: error.response.data.message },
          });
          setbuttonOk(false);
          Hata(error);

          return;
        });
    }
  }

  return (
    <>   <Hatamodal hata={errorS} seterrorS={seterrorS} />
      {detay && (
        <Modal
          size="md"
          show={detay ? true : false}
          onHide={() => setDetay()}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Sil
            </Modal.Title>
          </Modal.Header>
          <Modal.Body><strong>{detay.katAd}</strong> bilgisi silinsin mi?</Modal.Body>{" "}
          <Modal.Footer>
            {!buttonOk && (
              <>
                <Button variant="secondary" onClick={() => setDetay()}>
                  Vazgeç
                </Button>
                <Button variant="danger" onClick={() => onRemove()}>
                  Sil
                </Button>
              </>
            )}
            {buttonOk && <LoadSpin />}
          </Modal.Footer>
        </Modal>
      )}

    </>
  )

}

export default Sil