import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {  userPost } from "../../functionList/axiosComp";
import Hata from '../../functionList/hata';
import Hatamodal from '../../functionList/hatamodal'
import LoadSpin from '../../functionList/loadSpin';
import { useDispatch } from 'react-redux';
import { userLogin } from '../../redux/redux';
import { useNavigate } from "react-router-dom";

const Password = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonOk, setbuttonOk] = useState(false);
  const [loading, setLoading] = useState({});

  const [formValue, setformValue] = useState({
    sifre: "",
    sifret: "",
    sifreeski: "",
  });

  const [errorS, seterrorS] = useState({
    uyari: { error: false, message: "", tip: "" },
  });

  const HandleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const onsubmit = (e) => {
    e.preventDefault();
 

    if (formValue.sifreeski.length <= 4) {
      seterrorS({
        uyari: { error: true, message: "Eski şifrenizi girin", tip: "danger" },
      });

      return false;
    } else if (formValue.sifre.length <= 4) {
      seterrorS({
        uyari: {
          error: true,
          message: "Yeni şifrenizi girin.(Min 6 Karakter)",
          tip: "danger",
        },
      });

      return false;
    } else if (formValue.sifre !== formValue.sifret) {
      seterrorS({
        uyari: {
          error: true,
          message: "Şifre tekrarınız uyuşmuyor.",
          tip: "danger",
        },
      });

      return false;
    } else {
      setbuttonOk(true);

      var form_data = new FormData();

      for (var key in formValue) {
        form_data.append(key, formValue[key]);
      }

      userPost("EB_Admin/sifreGunc/", form_data)
        .then((a) => {
  
          seterrorS({
            uyari: {
              error: true,
              message: "Şifreniz güncellendi",
              tip: "success",
            },
          });

          setformValue({
            sifre: "",
            sifret: "",
            sifreeski: "",
          });

        })
        .catch(function (error) {
          setbuttonOk(false);
          seterrorS({
            uyari: {
              tip: "error",
              error: true,
              message: error.response.data.message,
            },
          });

          console.log(error)
          Hata(error);
          return;
        });
    }

    return false;
  };

  return (
    <>
      <Hatamodal hata={errorS} seterrorS={seterrorS} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={(e) => onsubmit(e)}>
                  <h1>Şifre Güncelle </h1>

                  <div>
                    <div class="mb-3 position-relative">
                      <label class="form-label">Eski Şifre</label>
                      <div>
                        <input
                          type="password"
                          class="form-control"
                          data-parsley-minlength="5"
                          placeholder="Min 5 Karakter"
                          name="sifreeski"
                          onChange={HandleChange}
                          value={formValue.sifreeski}
                        />
                      </div>
                    </div>

                    <div class="mb-3 position-relative">
                      <label class="form-label">Yeni Şifre</label>
                      <div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Min 5 Karakter"
                          name="sifre"
                          onChange={HandleChange}
                          value={formValue.sifre}
                        />
                      </div>
                    </div>

                    <div class="mb-3 position-relative">
                      <label class="form-label">Yeni Şifre Tekrar</label>
                      <div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Min 5 Karakter"
                          name="sifret"
                          onChange={HandleChange}
                          value={formValue.sifret}
                        />
                      </div>
                    </div>

                    <button class="btn btn-primary waves-effect waves-light">
                      Güncelle
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password